<template lang="html">
  <div>
    <nav class="ui top fixed menu">
      <router-link to="/"><span>Survello</span></router-link>
    </nav>
    <main class="ui container login">
      <div class="ui yellow message" v-if="sessionInvalid">
        <div class="header">Sitzung abgelaufen</div>
        <p>
          Ihre letzte Sitzung ist abgelaufen, da Ihr Benutzerkonto auf einem
          anderen Gerät genutzt worden ist.
        </p>
      </div>
      <h3>Anmeldung</h3>
      <p v-if="sessionInvalid">
        Bitte melden Sie sich bei Survello an, um erneut auf Ihre Aufträge
        zugreifen zu können.
      </p>
      <p v-else>
        Bitte melden Sie sich bei Survello an, um auf Ihre Aufträge zugreifen zu
        können.
      </p>
      <form class="ui form" v-on:submit.prevent="login">
        <div class="field required" :class="{ disabled: loading }">
          <label class="label__username">Benutzername</label>
          <input
            @input="error = null"
            type="text"
            name="username"
            placeholder="Benutzername"
            autocomplete="username"
            autofocus="true"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="none"
            minlength="3"
            required
          />
        </div>
        <div class="field required" :class="{ disabled: loading }">
          <label class="label__password">Passwort</label>
          <input
            @input="error = null"
            type="password"
            name="password"
            placeholder="Passwort"
            autocomplete="current-password"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="none"
            minlength="3"
            required
          />
        </div>
        <div v-if="error" class="ui red message">
          <div class="header">Anmeldung fehlgeschlagen</div>
          <p>{{ error }}</p>
        </div>
        <button
          class="fluid ui right labeled icon primary button large btn-submit"
          :class="{ loading: loading }"
        >
          <i class="right arrow icon"></i>Anmelden
        </button>
      </form>
    </main>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      error: null,
      sessionInvalid: false,
    };
  },
  mounted() {
    if (this.$route.query.sessionInvalid) {
      this.sessionInvalid = true;
    }

    console.log("isPoolActive", process.env.VUE_APP_JOBPOOL_ACTIVE);
  },
  methods: {
    login() {
      this.loading = true;
      const username = $.trim($("input[name=username]").val());
      const password = $.trim($("input[name=password]").val());
      const credentials = {
        nickname: username,
        password,
      };

      this.$store
        .dispatch("user/login", credentials)
        .then(() => this.$store.dispatch("sync/download", credentials))
        .then(() =>
          this.$router.push({
            path: process.env.VUE_APP_JOBPOOL_ACTIVE ? "/projects" : "/jobs",
          })
        )
        .catch(() => {
          this.error =
            "Die verwendeten Zugangsdaten sind ungültig. Bitte prüfen Sie Ihre Angaben und probieren es erneut!";
          this.loading = false;
        });
    },
  },
};
</script>
