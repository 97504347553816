import Vue from "@/main";
import store from "@/store/store";
import {
  TIMELOG_ADD,
  TIMELOG_UPDATE,
  TIMELOG_UPDATE_CURRENT,
  TIMELOG_SET_CURRENT,
  TIMELOG_SET_CURRENT_ATTRIBUTE,
  TIMELOG_SET_CURRENT_PICTURE,
  TIMELOG_DELETE_CURRENT_PICTURE,
  TIMELOG_SAVE,
  TIMELOG_DELETE,
  TIMELOG_RESET,
} from "@/store/mutation-types";
import TimelogService from "@/services/TimelogService.js";
import orderBy from "lodash/orderBy";

export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  CLOSED: 2,
  SENT: 3,
});

function createNewTimelog({ jobId, userId, eventType }) {
  const timestamp = new Date().getTime();

  // If we create a timelog with the type "STOP", we need to find the related timelog for the event "START".
  let startId = timestamp;
  if (eventType === "stop") {
    const timelogs = store.state.timelog.list.filter(
      item => item.job_id == jobId && item.event_type == "start"
    );
    if (!timelogs || timelogs.length === 0)
      throw Error(
        `Timelog with eventType START for job ${jobId} not found in store`
      );

    if (timelogs.length > 1) {
      // Get latest timelog by sorting timestamp key
      const sortedTimelogs = timelogs.sort(
        (a, b) => new Date(b.event_time) - new Date(a.event_time)
      );
      startId = sortedTimelogs[0].id;
    } else {
      // Use only element as referencing timelog
      startId = timelogs[0].id;
    }
  }

  return {
    id: timestamp,
    start_id: startId,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    sync_status: 0,
    edit_status: 0,
    staff_id: parseInt(userId),
    job_id: parseInt(jobId),
    event_type: eventType,
    event_time: new Date().toISOString(),
    images: null,
  };
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
  current: null,
};

/**
 * Getters
 */
export const getters = {
  /**
   * Current selected timelog
   */
  current: state => state.current,
  /**
   * Array of all timelogs
   */
  all: state => state.list,
  /**
   * Array of all timelogs with sync pending
   */
  pending: state =>
    state.list.filter(
      timelog => timelog && timelog.sync_status !== SYNC_STATUS.SYNCED
    ),
  getById: state => id =>
    state.list.find(timelog => {
      return timelog.id === id;
    }),
  startByJobId: state => (jobId, userId) => {
    const timelogs = state.list.filter(
      element =>
        element.job_id == jobId &&
        element.staff_id == userId &&
        element.event_type === "start" &&
        Vue.$moment(element.event_time).isSame(Vue.$moment(), "day") &&
        element.edit_status < 2
    );
    // Order timelogs by event_time and return latest (most current) entry
    const orderedTimelogs = orderBy(timelogs, ["event_time"], ["desc"]);
    if (orderedTimelogs && orderedTimelogs.length > 0) {
      return orderedTimelogs[0];
    }
    return null;
  },
  stopByJobId: state => (jobId, userId, startId) =>
    state.list.find(
      element =>
        element.job_id === jobId &&
        element.staff_id === userId &&
        element.start_id === startId &&
        element.event_type === "stop" &&
        element.edit_status < 2
    ),
};

/**
 * Actions
 */
export const actions = {
  /**
   * Creates a new timelog entry
   * @param {*} commit
   * @param {*} timelog
   */
  create(_, { jobId, userId, eventType }) {
    return new Promise(async resolve => {
      const newTimelog = await createNewTimelog({
        jobId,
        userId,
        eventType,
      });
      // commit(TIMELOG_ADD, newTimelog);
      resolve(newTimelog);
    });
  },
  insert({ commit }, timelog) {
    return new Promise(resolve => {
      commit(TIMELOG_ADD, timelog);
      resolve(timelog);
    });
  },
  update({ commit }, { id, payload }) {
    return new Promise(resolve => {
      commit(TIMELOG_UPDATE, {
        id,
        payload,
      });
      resolve();
    });
  },
  updateCurrent({ commit }, { payload }) {
    return new Promise(resolve => {
      commit(TIMELOG_UPDATE_CURRENT, {
        payload,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} timelog
   */
  save({ commit, state }, timelog) {
    return new Promise(resolve => {
      const index = state.list.findIndex(element => element.id === timelog.id);
      if (index === -1)
        throw Error(`Timelog with id ${timelog.id} not found in store`);

      commit(TIMELOG_SAVE, {
        timelog,
        index,
      });
      resolve();
    });
  },
  /**
   * Sets the current timelog in state
   * @param {*} param0
   * @param {*} timelogId
   */
  setCurrent({ commit, state }, { id, timelog }) {
    return new Promise(resolve => {
      let localTimelog;
      if (id) {
        localTimelog = state.list.find(_timelog => _timelog.id == id);
        if (!localTimelog)
          throw Error(`Timelog with id ${id} not found in store`);
      } else if (timelog) {
        localTimelog = timelog;
      }

      commit(TIMELOG_SET_CURRENT, localTimelog);
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setCurrentAttribute({ commit }, { fieldName, value }) {
    return new Promise(resolve => {
      commit(TIMELOG_SET_CURRENT_ATTRIBUTE, {
        fieldName,
        value,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setCurrentPicture({ commit }, { fileName }) {
    return new Promise(resolve => {
      commit(TIMELOG_SET_CURRENT_PICTURE, {
        fileName,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  deleteCurrentPicture({ commit, state }, { timelogId, fileName }) {
    return new Promise(resolve => {
      // Find given timelog by id
      const timelog = state.list.find(_timelog => _timelog.id === timelogId);
      if (!timelog)
        throw Error(`Timelog with id ${timelogId} not found in store`);

      commit(TIMELOG_DELETE_CURRENT_PICTURE, {
        fileName,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} job_id
   */
  getLatestForJob({ state }, { jobId, userId, eventType }) {
    return new Promise(async resolve => {
      const timelog = await state.list.find(
        _timelog =>
          _timelog.job_id === jobId &&
          _timelog.event_type === eventType &&
          _timelog.staff_id === userId
      );
      resolve(timelog);
    });
  },
  /**
   * Return latest start time for a given job
   * @param {*} param0
   * @param {*} param1
   */
  getLatestStartTimerForJob({ dispatch }, { jobId, userId }) {
    return Promise.resolve().then(() => {
      let start;

      // Get the most recent START timelog
      return dispatch("getLatestTimerForJob", {
        jobId,
        userId,
        eventType: "start",
      })
        .then(startTimelog => {
          if (startTimelog) {
            start = startTimelog;

            // Get the most recent STOP timelog
            return dispatch("getLatestTimerForJob", {
              jobId,
              userId,
              startId: startTimelog.start_id,
              eventType: "start",
            });
          }
          return null;
        })
        .then(stopTimelog => {
          if (stopTimelog) {
            // If the START and STOP timelog don't match today, return null
            // because there weren't any timelogs today
            const today = Vue.$moment().format("YYYY-MM-DD");
            if (
              !start.created_at.startsWith(today) &&
              !stopTimelog.created_at.startsWith(today)
            ) {
              return null;
            }
          }
          return start;
        });
    });
  },
  getLatestTimerForJob({ state }, { jobId, userId, startId, eventType }) {
    return Promise.resolve().then(() => {
      if (eventType === "stop" && !startId) {
        console.log("STOP detected nut no startId present, aborting.");
        return null;
      }

      if (state.list.length === 0) {
        return null;
      }

      let timelogs;
      if (startId) {
        timelogs = state.list.filter(
          _timelog =>
            _timelog.job_id === jobId &&
            _timelog.event_type === eventType &&
            _timelog.staff_id === userId &&
            _timelog.edit_status === 0 &&
            _timelog.start_id === startId
        );
      } else {
        timelogs = state.list.filter(
          _timelog =>
            _timelog.job_id === jobId &&
            _timelog.event_type === eventType &&
            _timelog.staff_id === userId &&
            _timelog.edit_status === 0
        );
      }

      if (!timelogs || timelogs.length === 0) {
        return null;
      }
      if (timelogs.length === 1) {
        return timelogs[0];
      }

      // eslint-disable-next-line no-nested-ternary
      const sortedTimelogs = timelogs.sort(
        (a, b) => new Date(b.event_time) - new Date(a.event_time)
      );
      return sortedTimelogs[0];
    });
  },
  completeExistingTimelogsForJob({ commit, state }, { jobId, userId }) {
    return Promise.resolve().then(() => {
      const timelogs = state.list.filter(
        _timelog =>
          _timelog.job_id === jobId &&
          _timelog.staff_id === userId &&
          _timelog.edit_status === 0
      );

      if (!timelogs || timelogs.length === 0) {
        return null;
      } else {
        return timelogs.map(timelog => {
          commit(TIMELOG_UPDATE, {
            id: timelog.id,
            payload: {
              edit_status: 2,
            },
          });
        });
      }
    });
  },
  /**
   * Resets all available data
   */
  reset({ commit }) {
    return new Promise(resolve => {
      commit(TIMELOG_RESET);
      resolve();
    });
  },
  fetchAll({ commit }, credentials) {
    return Promise.resolve()
      .then(() => TimelogService.fetchAll(credentials))
      .then(response => {
        const timelogs = response.data.data;
        timelogs.map(timelog => {
          commit(TIMELOG_ADD, timelog);
        });
      })
      .catch(error => {
        console.error("Error on fetching all users", error.stack || error);
        throw error;
      });
  },
  /**
   * Uploads all pending timelogs
   */
  uploadPending() {
    return new Promise((resolve, reject) => {
      const items = store.getters["timelog/pending"];
      console.log("[timelog/uploadPending] called", items);

      // Check if pending items are available
      if (!items || items.length === 0) {
        console.log("[timelog/uploadPending] No pending items available");
        resolve();
      } else {
        // Upload responses
        items
          .reduce(
            (sequence, item) =>
              sequence.then(() => {
                console.log("[timelog/uploadPending] Uploading…", item);
                return store.dispatch("timelog/upload", item);
              }),
            Promise.resolve()
          )
          .then(() => {
            console.log("[timelog/uploadPending] Uploaded with success");
            resolve();
          })
          .catch(error => {
            console.error(
              "[timelog/uploadPending] Error while uploading",
              error
            );
            reject(error);
          });
      }
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} timelog
   */
  upload({ commit, dispatch }, timelog) {
    return new Promise((resolve, reject) => {
      console.log("[timelog/upload] called", timelog);
      // Set sync state to pending
      dispatch("update", {
        id: timelog.id,
        payload: {
          sync_status: 1,
        },
      })
        .then(() =>
          // Upload images for timelog
          store.dispatch("image/uploadByReferenceId", {
            referenceId: timelog.id,
          })
        )
        .then(() =>
          // Upload timelog
          TimelogService.upload(timelog)
        )
        .then(response => {
          if (response.status != 200 && response.status != 201)
            throw new Error("Error uploading timelog");
          return response.data.data;
        })
        .then(() => {
          // Save sync state
          commit(TIMELOG_UPDATE, {
            id: timelog.id,
            payload: {
              uploaded_at: new Date().toISOString(),
              sync_status: 2,
            },
          });
          resolve();
        })
        .then(() => resolve())
        .catch(error => {
          // Catch error and set timelog to pending
          dispatch("update", {
            id: timelog.id,
            payload: {
              sync_status: 0,
            },
          });
          reject(error);
        });
    });
  },
  /**
   * Pauses an existing the timelog for a given job by adding a new record
   *
   * @param {*} param0
   * @param {*} param1
   */
  async pause({ dispatch }, { jobId, userId }) {
    return Promise.resolve()
      .then(() =>
        dispatch("create", {
          jobId: jobId,
          userId: userId,
          eventType: "stop",
        })
          .then(newTimelog => dispatch("insert", newTimelog))
          .then(_ =>
            dispatch(
              "job/update",
              {
                id: jobId,
                payload: {
                  paused_at: new Date().toISOString(),
                },
              },
              { root: true }
            )
          )
          .then(() => dispatch("sync/start", null, { root: true }))
      )
      .catch(error => {
        console.error("Error on pausing timelog", error.stack || error);
        throw error;
      });
  },
};

/**
 * Mutations
 */
export const mutations = {
  [TIMELOG_ADD](state, timelog) {
    const index = state.list.findIndex(element => element.id == timelog.id);
    if (index === -1) {
      timelog.sync_status = SYNC_STATUS.PENDING;
      state.list.push(timelog);
    } else {
      let localTimelog = state.list.find(_job => _job.id == timelog.id);
      localTimelog = Object.assign(localTimelog, timelog);
      localTimelog.sync_status = SYNC_STATUS.PENDING;

      state.list[index] = localTimelog;
    }
  },

  [TIMELOG_UPDATE](state, { id, payload }) {
    const index = state.list.findIndex(element => element.id == id);
    if (index === -1) throw Error(`Timelog with id ${id} not found in store`);

    let timelog = state.list.find(element => element.id == id);
    payload.updated_at = new Date().toISOString();
    timelog = Object.assign(timelog, payload);

    state.list[index] = timelog;
  },

  [TIMELOG_UPDATE_CURRENT](state, { payload }) {
    state.current = Object.assign(state.current, payload);
  },

  [TIMELOG_SAVE](state, { timelog, index }) {
    state.list.splice(index, 1, timelog);
  },

  [TIMELOG_DELETE](state, { index }) {
    state.list.splice(index, 1);
  },

  [TIMELOG_SET_CURRENT](state, timelog) {
    state.current = timelog;
  },

  [TIMELOG_SET_CURRENT_ATTRIBUTE](state, { fieldName, value }) {
    state.current[fieldName] = value;
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [TIMELOG_SET_CURRENT_PICTURE](state, { fileName }) {
    if (!state.current.images) {
      state.current.images = [];
    }
    state.current.images.push({
      fileName,
      url: null,
    });
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [TIMELOG_DELETE_CURRENT_PICTURE](state, { fileName }) {
    const index = state.current.images.findIndex(
      element => element.fileName === fileName
    );
    if (index === -1)
      throw Error(`Image with fileName ${fileName} not found in timelog`);

    state.current.images.splice(index);
  },
  /**
   * Resets state for timelogs entity
   * @param {*} state
   */
  [TIMELOG_RESET](state) {
    state.current = null;
    state.list = [];
  },
};
