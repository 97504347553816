import { apiClient } from "./ApiClient";

export default {
  upload(params) {
    const remark = {
      id: params.id,
      description: params.description,
      images: params.images,
      editStatus: params.edit_status,
      syncStatus: params.sync_status,
      staffId: params.user_id,
      jobId: params.job_id,
    };
    return apiClient.sharedInstance().patch("remarks", remark);
  },
};
