<template>
  <modal
    black
    v-model="isDeletePhotoModalShowing"
    modalVariation="basic"
    style="padding-top: 200px"
  >
    <div slot="header" class="center">
      <i class="trash icon"></i>
      Foto löschen
    </div>
    <div class="content" slot="content">
      <p>Möchten Sie dieses Foto wirklich löschen?</p>
    </div>
    <template slot="actions">
      <div
        class="ui black deny inverted button"
        @click="cancelDeletePhotoModal"
      >
        <i class="remove icon"></i> Nein
      </div>
      <div
        class="ui green right inverted button"
        @click="confirmDeletePhotoModal"
      >
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";
import { EventBus } from "@/event-bus";

export default {
  name: "ModalImageCaptureDelete",
  props: [
    "isDeletePhotoModalShowing",
    "isDeletePhotoModalConfirmed",
    "fileName",
  ],
  components: {
    modal,
  },
  methods: {
    cancelDeletePhotoModal() {
      this.$emit("update:isDeletePhotoModalShowing", false);
      this.$emit("update:isDeletePhotoModalConfirmed", false);
    },
    confirmDeletePhotoModal() {
      this.cancelDeletePhotoModal();
      this.$emit("update:isDeletePhotoModalConfirmed", true);
      EventBus.$emit("SHOW_DELETEPHOTO_MODAL_CONFIRMED", this.fileName);
    },
  },
};
</script>
