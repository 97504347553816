<template>
  <nav class="ui top fixed menu two column grid wide" v-if="this.job">
    <router-link :to="{ name: 'job', params: { id: this.job.id } }">
      <div class="column" style="line-height: 24px; padding-top: 14px">
        <i class="arrow left large icon"></i>
      </div>
    </router-link>
    <div class="column menu__title left floated">
      <span>{{ this.title }}</span>
      <span class="subtitle">
        {{ this.job.title }}
      </span>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBarTimelogEntry",
  props: ["title", "job"],
};
</script>

<style scoped>
.ui[class*="two column"].grid > .column:not(.row),
.ui[class*="two column"].grid > .row > .column {
  width: 80%;
}
nav .menu__title {
  padding-top: 0px !important;
}
nav span {
  padding-top: 8px;
}
.ui.grid > * {
  padding-right: 0px !important;
}
</style>
