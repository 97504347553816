<template>
  <button
    v-if="isVisible"
    class="ui fluid left labeled icon big primary button"
    @click="continueTimelog"
  >
    <i class="left play icon"></i>
    Arbeit fortsetzen
  </button>
</template>

<script>
export default {
  name: "ButtonJobContinue",
  props: ["jobId", "userId", "isVisible"],
  methods: {
    continueTimelog() {
      this.$store
        .dispatch("job/continue", {
          jobId: this.jobId,
          userId: this.userId,
          eventType: "start",
        })
        .catch(error => {
          console.error("Error continue job", error);
        });
    },
  },
};
</script>
