<template>
  <div>
    <!-- Navigation -->
    <NavBarJob title="Interne Fertigmeldung" :job="job"></NavBarJob>

    <main class="ui container">
      <form class="ui form" @submit.prevent="saveApproval" v-if="approval">
        <DateInput
          :id="approval.id"
          :time="approval.approved_at"
          fieldName="approved_at"
          entity="approval"
          hidden
        ></DateInput>
        <FileCaptureImage
          :job="job"
          :approval="approval"
          entity="approval"
          v-on:captured-image="handleDraftImageSelection($event)"
          v-on:deleted-image="handleDraftImageDeletion($event)"
        ></FileCaptureImage>

        <button
          class="ui fluid left labeled icon huge green button"
          type="submit"
          style="margin-top: 1em"
          :class="{ loading }"
        >
          <i class="check icon"></i>
          Fertigmeldung speichern
        </button>
      </form>
    </main>

    <!-- Modal Cancel -->
    <ModalCancel
      :isConfirmLeavingViewModalShowing.sync="isConfirmLeavingViewModalShowing"
      :isConfirmLeavingViewModalConfirmed.sync="
        isConfirmLeavingViewModalConfirmed
      "
    ></ModalCancel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavBarJob from "@/components/NavBarJob.vue";
import DateInput from "@/components/form/DateInput.vue";
import ModalCancel from "@/components/modal/ModalCancel.vue";
import FileCaptureImage from "@/components/form/FileCaptureImage.vue";
import { EventBus } from "@/event-bus";

export default {
  name: "JobApproval",
  components: {
    NavBarJob,
    DateInput,
    FileCaptureImage,
    ModalCancel,
  },
  data() {
    return {
      loading: false,
      cancelRoute: null,
      isConfirmLeavingViewModalShowing: false,
      isConfirmLeavingViewModalConfirmed: false,
      draftImages: [],
    };
  },
  computed: {
    ...mapGetters({
      job: "job/current",
      user: "user/current",
      approval: "approval/current",
    }),
  },
  watch: {
    // Fetch data if the route changes
    $route: "setCurrentApproval",
  },
  beforeRouteLeave(to, from, next) {
    // Show confirmation alert when user taps on the back button
    if (this.isConfirmLeavingViewModalConfirmed) {
      next();
    } else {
      this.showLeaveViewAlert(to);
      next(false);
    }
  },
  mounted() {
    this.setCurrentApproval();

    // Handle cancel confirmation for leave modal
    EventBus.$once("cancelModalConfirmed", () => {
      this.isConfirmLeavingViewModalConfirmed = true;
      this.deleteDraftImages();

      this.$router.push({
        name:
          this.cancelRoute && this.cancelRoute.name
            ? this.cancelRoute.name
            : null,
        params: this.cancelRoute.params,
      });
    });

    // Delete pending images on cancel
    EventBus.$once("CAPTURED_IMAGE", fileName => {
      if (!this.capturedImages.includes(fileName)) {
        this.capturedImages.push(fileName);
      }
    });
  },
  methods: {
    setCurrentApproval() {
      this.$store
        .dispatch("approval/getLatestForJob", {
          jobId: this.$route.params.jobId,
          userId: this.user.id,
        })
        .then(approval => this.$store.dispatch("approval/setCurrent", approval))
        .catch(() => {
          this.$router.push({
            name: "jobs",
          });
        });
    },
    handleDraftImageSelection: function (event) {
      if (event && !this.draftImages.includes(event)) {
        this.draftImages.push(event);
      }
    },
    handleDraftImageDeletion: function (event) {
      if (event && this.draftImages.includes(event)) {
        this.draftImages.pop(event);
      }
    },
    deleteDraftImages() {
      this.draftImages.map(image => {
        this.$store.dispatch("image/delete", {
          fileName: image,
        });
      });
    },
    saveApproval() {
      this.loading = true;
      this.isConfirmLeavingViewModalConfirmed = true;

      this.$store
        .dispatch("approval/update", {
          id: this.approval.id,
          payload: {
            edit_status: 2,
          },
        })
        .then(() =>
          this.$store.dispatch("job/update", {
            id: this.$route.params.jobId,
            payload: {
              edit_status: 1,
              sync_status: 0,
              approved_at: new Date().toISOString(),
            },
          })
        )
        .then(() => this.$store.dispatch("approval/save", this.approval))
        .then(() => {
          this.loading = false;
          this.$store.dispatch("sync/start");
          this.$router.push({
            name: "job",
            params: { jobId: this.$route.params.jobId },
          });
        });
    },
    showLeaveViewAlert(to) {
      this.isConfirmLeavingViewModalShowing = true;
      this.isConfirmLeavingViewModalConfirmed = false;
      this.cancelRoute = to;
    },
  },
};
</script>
