<template>
  <div class="ui field required" :class="{ error: isEarlierThanStartDate }">
    <label>Uhrzeit</label>
    <p>Trage die korrekte Uhrzeit ein.</p>
    <input
      type="time"
      name="time"
      min="0:00"
      max="23:59"
      placeholder="12:00"
      :value="inputTime"
      @change="setAttribute"
      required
    />
    <div v-if="isEarlierThanStartDate" class="ui message red">
      <p>
        Das Arbeitsende muss
        <u>nach</u>
        dem Arbeitsstart am
        {{ startTimelog.event_time | moment("DD.MM.YYYY") }} um
        {{ startTimelog.event_time | moment("HH:mm") }} Uhr liegen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateInput",
  props: ["id", "time", "fieldName", "entity", "user"],
  data() {
    return {
      timeDraft: null,
      isStopTimelog: null,
    };
  },
  computed: {
    inputTime() {
      if (this.time) {
        // eslint-disable-next-line
        this.timeDraft = this.$moment(this.time);
        return this.$moment(this.time).format("HH:mm");
      }
      return this.$moment().format("HH:mm");
    },
  },
  asyncComputed: {
    startTimelog: {
      get() {
        if (!this.user || !this.$route.params.jobId) {
          return null;
        }

        return this.$store.dispatch("timelog/getLatestStartTimerForJob", {
          jobId: this.$route.params.jobId,
          userId: this.user.id,
          eventType: "start",
        });
      },
    },
    isEarlierThanStartDate: {
      get() {
        if (this.startTimelog) {
          const inputTimestamp = this.$moment(this.timeDraft);
          const startTimestamp = this.$moment(this.startTimelog.event_time);
          return this.$moment(inputTimestamp).isBefore(startTimestamp);
        }
        return false;
      },
    },
  },
  mounted() {
    // Set title for navigation bar
    if (this.$route === "timelogStop") {
      this.isStopTimelog = true;
    }
  },
  methods: {
    setAttribute(e) {
      // Transform input to valid timestamp
      const input = e.target.value;
      const transformedTime = this.$moment(input, "HH:mm").toISOString();
      this.timeDraft = transformedTime;

      const payload = {};
      payload[this.fieldName] = transformedTime;

      this.$store.dispatch(`${this.entity}/updateCurrent`, {
        payload,
      });
    },
  },
};
</script>
