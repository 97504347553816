import { apiClient } from "./ApiClient";

export default {
  fetchAll(credentialsParams) {
    return apiClient.sharedInstance().get("approvals", null, {
      params: credentialsParams,
    });
  },

  upload(params) {
    // Limit number of images to 1
    let image;
    if (params.images && params.images.length > 0) {
      image = params.images[0].fileName;
    }

    const approval = {
      id: params.id,
      approvedAt: params.approved_at,
      editStatus: params.edit_status,
      syncStatus: params.sync_status,
      image: image,
      staffId: params.staff_id,
      jobId: params.job_id,
    };
    return apiClient.sharedInstance().post("approvals", approval);
  },
};
