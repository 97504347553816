<template>
  <div>
    <!-- Navigation -->
    <NavBarJob :title="title" :job="job"></NavBarJob>

    <main class="ui container">
      <form class="ui form" @submit.prevent="saveTimelog" v-if="job">
        <FileCaptureImage
          :job="job"
          :timelog="timelog"
          entity="timelog"
          v-on:captured-image="handleDraftImageSelection($event)"
          v-on:deleted-image="handleDraftImageDeletion($event)"
        ></FileCaptureImage>

        <button
          class="ui fluid left labeled icon huge green button"
          type="submit"
          style="margin-top: 1em"
          :class="{ loading }"
        >
          <i class="save icon"></i>
          Speichern
        </button>
      </form>
    </main>

    <!-- Modal Cancel -->
    <ModalCancel
      :isConfirmLeavingViewModalShowing.sync="isConfirmLeavingViewModalShowing"
      :isConfirmLeavingViewModalConfirmed.sync="
        isConfirmLeavingViewModalConfirmed
      "
    ></ModalCancel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import NavBarJob from "@/components/NavBarJob.vue";
import ModalCancel from "@/components/modal/ModalCancel.vue";
import FileCaptureImage from "@/components/form/FileCaptureImage.vue";

export default {
  name: "Timelog",
  components: {
    NavBarJob,
    FileCaptureImage,
    ModalCancel,
  },
  data() {
    return {
      loading: false,
      title: null,
      cancelRoute: null,
      isConfirmLeavingViewModalShowing: false,
      isConfirmLeavingViewModalConfirmed: false,
      draftImages: [],
    };
  },
  computed: {
    ...mapGetters({
      timelog: "timelog/current",
      user: "user/current",
    }),
    job() {
      return this.$store.getters["job/getById"](
        parseInt(this.$route.params.jobId)
      );
    },
    startTimelog() {
      return this.$store.getters["timelog/getById"](
        parseInt(this.$route.params.timelogId)
      );
    },
  },
  watch: {
    // Fetch data if the route changes
    $route: "setCurrentJob",
  },
  beforeRouteLeave(to, from, next) {
    // Show confirmation alert when user taps on the back button
    if (this.isConfirmLeavingViewModalConfirmed) {
      next();
    } else {
      this.showLeaveViewAlert(to);
      next(false);
    }
  },
  created() {
    // Set title for navigation bar
    const { name } = this.$route;
    if (name === "timelogStart") {
      this.title = "Arbeit starten";
    } else if (name === "timelogStop") {
      this.title = "Arbeit stoppen";
    }

    // Get data
    this.setCurrentJob();

    // Handle cancel confirmation for leave modal
    EventBus.$once("cancelModalConfirmed", () => {
      this.isConfirmLeavingViewModalConfirmed = true;
      this.deleteDraftImages();

      const routeName =
        this.cancelRoute && this.cancelRoute.name
          ? this.cancelRoute.name
          : null;
      const routeParams =
        this.cancelRoute && this.cancelRoute.params
          ? this.cancelRoute.params
          : null;

      this.$router.push({
        name: routeName,
        params: routeParams,
      });
    });

    // Delete pending images on cancel
    EventBus.$once("CAPTURED_IMAGE", fileName => {
      if (!this.capturedImages.includes(fileName)) {
        this.capturedImages.push(fileName);
      }
    });
  },
  methods: {
    async setCurrentJob() {
      // If user selected an existing timelog, the id is present in the route.
      await this.$store
        .dispatch("timelog/create", {
          jobId: parseInt(this.$route.params.jobId),
          userId: this.user.id,
          eventType: this.$route.name === "timelogStart" ? "start" : "stop",
        })
        .then(newTimelog => {
          return this.$store.dispatch("timelog/setCurrent", {
            timelog: newTimelog,
          });
        });
    },
    showLeaveViewAlert(to) {
      this.isConfirmLeavingViewModalShowing = true;
      this.isConfirmLeavingViewModalConfirmed = false;
      this.cancelRoute = to;
    },
    handleDraftImageSelection: function (event) {
      if (event && !this.draftImages.includes(event)) {
        this.draftImages.push(event);
      }
    },
    handleDraftImageDeletion: function (event) {
      if (event && this.draftImages.includes(event)) {
        this.draftImages.pop(event);
      }
    },
    deleteDraftImages() {
      this.draftImages.map(image => {
        this.$store.dispatch("image/delete", {
          fileName: image,
        });
      });
    },
    async saveTimelog() {
      // Assign save or insert action based on timelog status
      this.isConfirmLeavingViewModalConfirmed = true;

      const timelogId = this.$route.params.timelogId;

      this.loading = true;

      // Pause all active jobs when starting a new timelog
      if (this.timelog.event_type == "start") {
        await this.$store.dispatch("job/pauseActiveJobs", {
          userId: this.user.id,
        });
      }

      this.$store
        .dispatch("timelog/insert", this.timelog)
        .then(newTimelog =>
          this.$store.dispatch("timelog/update", {
            id: newTimelog.id,
            payload: { sync_status: 0, edit_status: !timelogId ? 0 : 2 },
          })
        )
        .then(async () => {
          if (this.startTimelog) {
            return this.$store.dispatch("timelog/update", {
              id: this.startTimelog.id,
              payload: { sync_status: 0, edit_status: 2 },
            });
          }
          return true;
        })
        .then(() =>
          this.$store.dispatch("job/update", {
            id: parseInt(this.$route.params.jobId),
            payload: { sync_status: 0, paused_at: null },
          })
        )
        .then(() => {
          this.loading = false;
          this.$store.dispatch("sync/start");
          this.$router.push({
            name: "job",
            params: { jobId: parseInt(this.$route.params.jobId) },
          });
        });
    },
  },
};
</script>
