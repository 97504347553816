import { EventBus } from "@/event-bus";
import JobService from "@/services/JobService.js";
import {
  JOBS_ADD,
  JOBS_DELETE,
  JOBS_RESET,
  JOBS_SET_ACTIVE,
  JOBS_SET_CURRENT,
  JOBS_SET_EDITSTATUS,
  JOBS_UPDATE,
} from "@/store/mutation-types";
import store from "@/store/store";

const md5 = require("md5");

export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  CLOSED: 2,
  SENT: 3,
});

function createNewJob({ jobId, userId }) {
  return {
    id: md5(new Date().getTime()),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    sync_status: 0,
    staff_id: userId,
    job_id: jobId,
    description: null,
    images: null,
  };
}

function setActiveJobs(jobs) {
  let currentUser = store.getters["user/current"];
  if (!currentUser) {
    return false;
  }

  const activeJobs = jobs.filter(job => {
    // Check if job is currently paused
    if (job.paused_at) {
      return job;
    }

    // Get start timelog
    const startTimelog = store.getters["timelog/startByJobId"](
      job.id,
      currentUser.id
    );

    // Get stop timelog
    let stopTimelog = null;
    if (startTimelog && startTimelog.id) {
      stopTimelog = store.getters["timelog/stopByJobId"](
        job.id,
        currentUser.id,
        startTimelog.id
      );
    }

    // Get active flag
    const isJobActive = startTimelog && !stopTimelog && !job.paused_at;

    // Get paused flag
    const isPaused =
      startTimelog && stopTimelog && stopTimelog.id && job.paused_at;

    if (isJobActive || isPaused) {
      return job;
    }

    return null;
  });
  return activeJobs;
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
  current: null,
};

/**
 * Getters
 */
export const getters = {
  all: state => state.list,
  allByProjectId: state => id =>
    state.list.find(job => {
      return job.project_id === id;
    }),
  pending: state =>
    state.list.filter(job => job && job.sync_status !== SYNC_STATUS.SYNCED),
  current: state => state.current,
  getById: state => id =>
    state.list.find(job => {
      return job.id === id;
    }),
  active: state => setActiveJobs(state.list),
  activeByProjectId: (state, getters) => id =>
    getters.active.filter(job => {
      return job.project_id === id;
    }),
  inactive: (state, getters) =>
    state.list.filter(job => !getters.active.includes(job)),
  inactiveByProjectId: (state, getters) => id =>
    getters.inactive.filter(job => {
      return job.project_id === id;
    }),
};

/**
 * Actions
 */
export const actions = {
  create(_, { jobId, userId }) {
    return createNewJob({
      jobId,
      userId,
    });
  },

  update({ commit }, { id, payload }) {
    commit(JOBS_UPDATE, {
      id,
      payload,
    });
  },

  delete({ commit, state }, { jobId }) {
    const index = state.list.findIndex(element => element.id === jobId);
    if (index === -1) throw Error(`Job with id ${jobId} not found in store`);

    commit(JOBS_DELETE, {
      index,
    });
  },

  setCurrent({ commit }, { id }) {
    return new Promise(resolve => {
      commit(JOBS_SET_CURRENT, {
        id,
      });
      resolve();
    });
  },

  async pause({ dispatch }, { jobId, userId }) {
    return Promise.resolve()
      .then(() =>
        dispatch(
          "timelog/create",
          {
            jobId: jobId,
            userId: userId,
            eventType: "stop",
          },
          { root: true }
        ).then(() => {
          // Add timestamp to `paused_at` attribute of job
          return dispatch("update", {
            id: jobId,
            payload: {
              paused_at: new Date().toISOString(),
            },
          });
        })
      )
      .catch(error => {
        console.error("Error pausing job", error.stack || error);
        throw error;
      });
  },

  /**
   * Stops an existing timelog for a given jobId by insert
   * a new timelog with eventType 'stop'
   */
  async stop({ dispatch }, { jobId, userId }) {
    return new Promise(async (resolve, reject) => {
      try {
        let newTimelog = await dispatch(
          "timelog/create",
          {
            jobId: jobId,
            userId: userId,
            eventType: "stop",
          },
          { root: true }
        );

        if (!newTimelog) throw Error("Timelog not created");

        // Set forced_stop flag to indicate an automatically stopped timelog
        newTimelog["forced_stop"] = true;
        newTimelog["paused_at"] = null;
        await dispatch("timelog/insert", newTimelog, { root: true });

        // Reset the current paused_at timestamp for the given job
        await dispatch("update", {
          id: jobId,
          payload: {
            paused_at: null,
          },
        });

        resolve();
      } catch (error) {
        console.error("Error pausing job", error.stack || error);
        reject(error);
      }
    });
  },

  /**
   * If a new timelog with eventType start is inserted, we must
   * pause all running jobs/timelogs.
   */
  async pauseActiveJobs({ getters, dispatch }, { userId }) {
    console.log("[job/pauseActiveJobs] called", state.active);
    return Promise.all(
      getters.active.map(job => dispatch("pause", { jobId: job.id, userId }))
    );
  },

  /**
   * Stop all active jobs which had been started 11 hours or longer ago
   */
  async stopActiveJobs({ getters, dispatch }, { userId }) {
    let numberOfStoppedJobs = 0;

    let actions = getters.active.map(async job => {
      // Get current active timelog for given job
      const startTimelog = store.getters["timelog/startByJobId"](
        job.id,
        userId
      );

      // Calculate time difference in hours
      var timeDiffInHours =
        Math.abs(new Date() - new Date(startTimelog.created_at)) / 3600000;

      // Stop jobs which are older than 11 hours
      if (timeDiffInHours > 11) {
        numberOfStoppedJobs++;

        dispatch("stop", { jobId: job.id, userId });
      }
    });

    return Promise.all(actions)
      .then(() => {
        if (numberOfStoppedJobs > 0) {
          EventBus.$emit("autoStoppedJobs", numberOfStoppedJobs);
          dispatch("sync/start", null, { root: true });
        }
      })
      .catch(error => {
        console.error("Error stopping all active jobs", error, error.stack);
      });
  },

  /**
   * Continues the timelog for a given job by adding a new record
   */
  async continue({ dispatch }, { jobId, userId }) {
    return Promise.resolve()
      .then(() => dispatch("pauseActiveJobs", { userId }))
      .then(() =>
        dispatch(
          "timelog/create",
          {
            jobId: jobId,
            userId: userId,
            eventType: "start",
          },
          { root: true }
        )
          .then(newTimelog =>
            dispatch("timelog/insert", newTimelog, { root: true })
          )
          .then(() =>
            dispatch("update", {
              id: jobId,
              payload: {
                paused_at: null,
              },
            })
          )
          .then(() => dispatch("sync/start", null, { root: true }))
      )
      .catch(error => {
        console.error("Error on continue timelog", error.stack || error);
        throw error;
      });
  },

  reset({ commit }) {
    return new Promise(resolve => {
      commit(JOBS_RESET);
      resolve();
    });
  },

  async fetchAll({ commit, dispatch }, credentials) {
    return Promise.resolve()
      .then(() => JobService.fetchAll(credentials))
      .then(async response => {
        const jobs = response.data.data;
        jobs.map(job => {
          commit(JOBS_ADD, job);
        });
        await dispatch("deletePruned", {
          remoteJobs: jobs,
        });
      })
      .catch(error => {
        console.error("Error on fetching all users", error.stack || error);
        throw error;
      });
  },
  /**
   * Uploads all pending jobs
   */
  uploadPending() {
    return new Promise((resolve, reject) => {
      const items = store.getters["job/pending"];
      console.log("[jobs/uploadPending] called", items);

      // Check if pending items are available
      if (!items || items.length === 0) {
        console.log("[jobs/uploadPending] No pending items available");
        resolve();
      } else {
        // Upload responses
        items
          .reduce(
            (sequence, item) =>
              sequence.then(() => {
                console.log("[job/uploadPending] Uploading…", item);
                return store.dispatch("job/upload", item);
              }),
            Promise.resolve()
          )
          .then(() => {
            console.log("[job/uploadPending] Uploaded with success");
            resolve();
          })
          .catch(error => {
            console.error("[job/uploadPending] Error while uploading", error);
            reject(error);
          });
      }
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} timelog
   */
  upload({ commit, dispatch }, job) {
    return new Promise((resolve, reject) => {
      console.log("[job/upload] called", job);
      // Set sync state to pending
      dispatch("update", {
        id: job.id,
        payload: {
          sync_status: 1,
        },
      })
        .then(() =>
          // Upload images for timelog
          store.dispatch("image/uploadByReferenceId", {
            referenceId: job.id,
          })
        )
        .then(() =>
          // Upload job
          JobService.upload(job)
        )
        .then(response => {
          if (response.status != 200 && response.status != 201)
            throw new Error("Error uploading job");
          return response.data.data;
        })
        .then(() => {
          // Save sync state
          commit(JOBS_UPDATE, {
            id: job.id,
            payload: {
              uploaded_at: new Date().toISOString(),
              sync_status: SYNC_STATUS.SYNCED,
            },
          });
          resolve();
        })
        .then(() => resolve())
        .catch(async error => {
          // Catch error and set timelog to pending
          await dispatch("update", {
            id: job.id,
            payload: {
              sync_status: SYNC_STATUS.PENDING,
            },
          });
          reject(error);
        });
    });
  },

  async deletePruned({ state, dispatch }, { remoteJobs }) {
    return Promise.resolve().then(() => {
      state.list.forEach(async dbJob => {
        const isLocalJobAvailableOnServer = remoteJobs.find(
          job => job.id === dbJob.id
        );
        if (
          !isLocalJobAvailableOnServer &&
          dbJob.editStatus === EDIT_STATUS.ASSIGNED
        ) {
          await dispatch("delete", {
            jobId: dbJob.id,
          });
        }
      });
    });
  },

  cleanSynced({ dispatch }) {
    return new Promise(resolve => {
      console.log("[job/cleanSynced] called…");

      const items = store.getters["job/all"].filter(
        item =>
          item.edit_status === EDIT_STATUS.COMPLETED &&
          item.sync_status === SYNC_STATUS.SYNCED
      );
      if (!items || items.length === 0) {
        console.log(
          "[job/cleanSynced] No completed and synced items for entity jobs"
        );
        return resolve();
      }

      items.forEach(async item => {
        // Clean all approvals for the given job
        await store.dispatch("cleanSynced", {
          entity: "approval",
        });
        // Clean all timelogs for the given job
        await store.dispatch("cleanSynced", {
          entity: "timelog",
        });
        // Delete job
        await dispatch("delete", {
          jobId: item.id,
        });
      });

      return resolve();
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  [JOBS_ADD](state, job) {
    // Skip completed jobs from server
    if (job && job.edit_status > 0) {
      return;
    }

    const index = state.list.findIndex(element => element.id == job.id);
    if (index === -1) {
      job.sync_status = SYNC_STATUS.SYNCED;
      job.updated_at = new Date().toISOString();
      state.list.push(job);
    } else {
      let localJob = state.list.find(_job => _job.id == job.id);
      if (!localJob) throw Error(`Job with id ${job.id} not found in store`);

      // Skip completed jobs from local storage
      if (localJob && localJob.edit_status > 0) {
        return;
      }
      // Overwrite local job
      localJob = Object.assign(localJob, job);
      localJob.sync_status = SYNC_STATUS.SYNCED;

      state.list[index] = localJob;
    }
  },

  [JOBS_UPDATE](state, { id, payload }) {
    const index = state.list.findIndex(element => element.id == id);
    if (index === -1) throw Error(`Job with id ${id} not found in store`);

    let job = state.list.find(_job => _job.id == id);
    payload.updated_at = new Date().toISOString();
    job = Object.assign(job, payload);

    state.list[index] = job;
  },

  [JOBS_DELETE](state, { index }) {
    state.list.splice(index, 1);
  },

  [JOBS_SET_CURRENT](state, { id }) {
    const job = state.list.find(element => element.id == id);
    if (!job) throw Error(`Job with id ${id} not found in store`);

    state.current = job;
  },

  [JOBS_SET_ACTIVE](state, { jobs }) {
    console.log("SETTING ACTIVE JOBS", jobs);
    // state.active = jobs;
    // state.inactive = state.list.filter(job => !jobs.includes(job));
  },

  [JOBS_SET_EDITSTATUS](state, { jobId, editStatus }) {
    const job = state.list.find(_job => _job.id == jobId);
    if (!job) throw Error(`Job with id ${jobId} not found in store`);

    job.editStatus = editStatus;
    job.updated_at = new Date().toISOString();

    // Save timestamp when job has been finished
    if (editStatus === 1) {
      job.finished_at = new Date().toISOString();
    } else if (editStatus === 2) {
      job.approved_at = new Date().toISOString();
    }

    state.current = job;

    // Replace element in list
    const index = state.list.findIndex(element => element.id == jobId);
    if (index === -1) throw Error(`Job with id ${jobId} not found in store`);

    state.list[index] = job;
  },

  [JOBS_RESET](state) {
    state.current = null;
    state.list = [];
  },
};
