/* eslint-disable no-underscore-dangle */

export default function vuexPersistPatch() {
  return store => {
    store._vm.$root.$data["vue-persist-patch-delay"] = true;

    store.subscribe(mutation => {
      if (mutation.type === "RESTORE_MUTATION") {
        store._vm.$root.$data["vue-persist-patch-delay"] = false;
        store.dispatch("sync/setStatus", false);
        store._vm.$root.$emit("storageReady");
      }
    });
  };
}
