import { apiClient } from "./ApiClient";

export default {
  fetchAll(credentialsParams) {
    return apiClient.sharedInstance().get("timelogs", null, {
      params: credentialsParams,
    });
  },

  upload(params) {
    // Limit number of images to 1
    let image;
    if (params.images && params.images.length > 0) {
      image = params.images[0].fileName;
    }

    const timelog = {
      id: params.id,
      eventType: params.event_type,
      eventTime: params.event_time,
      image: image,
      editStatus: params.edit_status,
      syncStatus: params.sync_status,
      startId: params.start_id,
      staffId: params.staff_id,
      jobId: params.job_id,
    };
    return apiClient.sharedInstance().patch("timelogs", timelog);
  },
};
