/* eslint-disable no-shadow */
import UserService from "@/services/UserService.js";
import {
  USERS_ADD,
  USERS_RESET,
  USERS_SET_CURRENT,
} from "@/store/mutation-types";
import store from "@/store/store";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  // Current user
  current: null,
};

/**
 * Getters
 */
export const getters = {
  current: state => state.current,
  fullName: state => {
    if (
      !state.current ||
      !state.current.first_name ||
      !state.current.last_name
    ) {
      return null;
    }
    return `${state.current.first_name} ${state.current.last_name}`;
  },
};

/**
 * Actions
 */
export const actions = {
  /**
   * Fetch user from "user/login" API endpoint
   * @param {*} context
   */
  login({ commit }, credentials) {
    return UserService.loginUser(credentials)
      .then(response => {
        if (!response || !response.data || !response.data.data) {
          throw new Error("Username and password did not match");
        }
        const user = response.data.data;
        commit(USERS_SET_CURRENT, user);
      })
      .catch(error => {
        console.error("Error on login user", error.stack || error);
        throw error;
      });
  },
  /**
   * Logs current user out and wipes all data
   */
  logout({ commit }) {
    return Promise.resolve()
      .then(() => store.dispatch("approval/reset"))
      .then(() => store.dispatch("image/reset"))
      .then(() => store.dispatch("issue/reset"))
      .then(() => store.dispatch("job/reset"))
      .then(() => store.dispatch("timelog/reset"))
      .then(() => store.dispatch("project/reset"))
      .then(() => {
        commit(USERS_RESET);
      });
  },
  /**
   * Logs current user out after session become invalid
   */
  logoutWithoutReset({ commit }) {
    return Promise.resolve().then(() => {
      commit(USERS_RESET);
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} user
   */
  [USERS_ADD](state, user) {
    const index = state.list.findIndex(element => element.id === user.id);
    if (index === -1) {
      // Add user if not already available
      state.list.push({
        id: user.id,
        created_at: user.created_at,
        updated_at: user.updated_at,
        name: `${user.first_name} ${user.last_name}`,
        nickname: user.nickname,
      });
    } else {
      // Update existing user if already available
      let localUser = state.list.find(_user => _user.id === user.id);
      localUser = Object.assign(localUser, user);

      state.list[index] = {
        id: localUser.id,
        created_at: localUser.created_at,
        updated_at: localUser.updated_at,
        name: `${localUser.first_name} ${localUser.last_name}`,
        nickname: localUser.nickname,
      };
    }
  },

  /**
   * Sets the current user
   * @param {} state
   * @param {*} user
   */
  [USERS_SET_CURRENT](state, user) {
    state.current = user;
  },

  /**
   * Resets state for users entity
   * @param {*} state
   */
  [USERS_RESET](state) {
    state.current = null;
  },
};
