import { apiClient } from "./ApiClient";

export default {
  fetchAll(credentialsParams) {
    return apiClient.sharedInstance().get("jobs", null, {
      params: credentialsParams,
    });
  },

  upload(params) {
    const job = {
      id: params.id,
      editStatus: params.edit_status,
      syncStatus: params.sync_status,
    };
    return apiClient.sharedInstance().patch("jobs", job);
  },
};
