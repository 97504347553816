<template>
  <modal
    v-model="isJobCompleteTimelogsModalShowing"
    modalVariation="basic"
    style="padding-top: 22em"
  >
    <div slot="header" class="center">
      <i class="warning sign icon"></i>
      Neuen Zeitabschnitt starten
    </div>
    <div class="content" slot="content">
      <p>
        Wenn ein neuer Zeitabschnitt gestartet wird, können Sie den zuletzt
        dargestellten Zeiteintrag nur noch im Cockpit bearbeiten.
      </p>
      <p>
        <strong>Möchten Sie wirklich einen neuen Zeitabschnitt starten?</strong>
      </p>
    </div>
    <template slot="actions">
      <div class="ui black deny inverted button" @click="cancelModal">
        <i class="remove icon"></i> Nein
      </div>
      <div class="ui green right inverted button" @click="confirmModal">
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";
import { EventBus } from "@/event-bus";

export default {
  name: "ModalJobCompleteTimelogs",
  props: [
    "jobId",
    "isJobCompleteTimelogsModalShowing",
    "isJobCompleteTimelogsConfirmed",
  ],
  components: {
    modal,
  },
  methods: {
    cancelModal() {
      this.$emit("update:isJobCompleteTimelogsModalShowing", false);
      this.$emit("update:isJobCompleteTimelogsConfirmed", false);
    },
    confirmModal() {
      this.cancelModal();
      this.$emit("update:isJobCompleteTimelogsConfirmed", true);
      EventBus.$emit("SHOW_JOBCOMPLETETIMELOGS_MODAL_CONFIRMED", this.jobId);
    },
  },
};
</script>
