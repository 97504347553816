import store from "@/store/store";
import Approval from "@/views/Approval";
import Home from "@/views/Home";
import Issue from "@/views/Issue";
import Job from "@/views/Job";
import Jobs from "@/views/Jobs";
import Login from "@/views/Login";
import Projects from "@/views/Projects";
import Remark from "@/views/Remark";
import Settings from "@/views/Settings";
import SettingsDiagnosis from "@/views/SettingsDiagnosis";
import Sync from "@/views/Sync";
import Timelog from "@/views/Timelog";
import LogRocket from "logrocket";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      name: "home",
      meta: {
        forwardAuthUser: true,
      },
      component: Home,
    },
    {
      path: "/login",
      name: "login",
      props: true,
      meta: {
        requiresAuth: false,
        forwardAuthUser: true,
      },
      component: Login,
    },
    {
      path: "/jobs",
      name: "jobs",
      meta: {
        requiresAuth: true,
      },
      component: Jobs,
    },
    {
      path: "/projects",
      name: "projects",
      meta: {
        requiresAuth: true,
      },
      component: Projects,
    },
    {
      path: "/jobs/:jobId",
      name: "job",
      component: Job,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/jobs/:jobId/start/:timelogId?",
      name: "timelogStart",
      component: Timelog,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/jobs/:jobId/stop/:timelogId?",
      name: "timelogStop",
      component: Timelog,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/jobs/:jobId/approval/:approvalId",
      name: "jobApproval",
      component: Approval,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/jobs/:jobId/issue/:issueId",
      name: "jobIssue",
      component: Issue,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/jobs/:jobId/remark/:remarkId",
      name: "jobRemark",
      component: Remark,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sync",
      name: "sync",
      meta: {
        requiresAuth: true,
      },
      component: Sync,
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        requiresAuth: true,
      },
      component: Settings,
    },
    {
      path: "/settings/diagnosis",
      name: "diagnosis",
      component: SettingsDiagnosis,
      meta: {
        requiresAuth: true,
      },
    },
  ],
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Hold the request, until the Storage is available
  // eslint-disable-next-line
  if (store._vm.$root.$data["vue-persist-patch-delay"]) {
    // eslint-disable-next-line
    store._vm.$root.$on("storageReady", () => {
      next({
        path: to.path,
        query: to.query,
        params: to.params,
      });
    });
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // This route requires an authenticated user, check if the user is
    // logged in. If not, redirect to login page.
    const user = store.getters["user/current"];
    if (!user || (user && !user.id)) {
      next({
        path: "/login",
      });
    } else {
      LogRocket.identify(user.id);
      next();
    }
  } else if (to.matched.some(record => record.meta.forwardAuthUser)) {
    // This route forwards already authenticated users to the jobs
    // overview else show the desired route
    const user = store.getters["user/current"];
    if (user && user.id) {
      next({
        path: "/jobs",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
