import { apiClient } from "./ApiClient";

export default {
  loginUser(credentialsParams) {
    return apiClient.sharedInstance().post(`login`, null, {
      params: credentialsParams,
    });
  },
  fetchAll(credentialsParams) {
    return apiClient.sharedInstance().get("staff", null, {
      params: credentialsParams,
    });
  },
};
