/**
 * abstract/api.js
 */

const abstractMutations = {
  delete({ id, entity }) {
    console.log("[abstract/delete] Id", id, entity);
  },
};

export default abstractMutations;
