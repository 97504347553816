<template>
  <nav class="ui top fixed menu two column grid wide">
    <div class="column menu__title">
      <span>Projektauswahl</span>
      <span v-if="this.fullName" class="subtitle">{{ this.fullName }}</span>
    </div>
    <div class="column menu__buttons">
      <div
        class="ui button inverted icon white menu__buttons-sync"
        :class="{ loading: loading }"
        :disabled="loading"
        @click="loadFromRemote"
      >
        <i class="refresh icon"></i>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "NavBarJobs",
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.current,
      loading: state => state.sync.isActive,
    }),
    ...mapGetters({
      fullName: "user/fullName",
    }),
  },
  methods: {
    loadFromRemote() {
      this.$store.dispatch("sync/download");
    },
  },
};
</script>

<style>
nav span.subtitle {
  font-size: 14px;
  padding-top: 4px !important;
  color: rgba(255, 255, 255, 0.54);
}

.menu__buttons {
  padding-top: 9px !important;
  padding-right: 1rem;
  text-align: right;
}
.menu__buttons-user {
  margin-right: 12px !important;
}
.menu__buttons-sync {
  margin-right: 0 !important;
}
</style>

<style scoped>
nav .menu__title {
  padding-top: 0px !important;
}
nav span {
  padding-top: 8px;
}
</style>
