<template>
  <div class="tabbar-container ui three column grid">
    <div class="row">
      <div class="column tabbar-item">
        <router-link to="/jobs">
          <div>
            <img src="./../assets/tab-inquiries.png" width="24px" />
            <p>Aufgaben</p>
          </div>
        </router-link>
      </div>
      <div class="column tabbar-item">
        <router-link to="/sync">
          <div>
            <img src="./../assets/tab-sync.png" width="24px" />
            <p>Warteschlange</p>
            <div
              class="floating ui red label"
              :class="{ red: !isSyncing, blue: isSyncing }"
              v-if="queueCount > 0"
            >
              {{ queueCount }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="column tabbar-item">
        <router-link to="/settings">
          <div>
            <img src="./../assets/tab-settings.png" width="24px" />
            <p>Einstellungen</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TabBar",
  computed: {
    ...mapGetters({
      jobs: "job/pending",
      issues: "issue/pending",
      images: "image/pending",
      timelogs: "timelog/pending",
      approvals: "approval/pending",
      isSyncing: "sync/isActive",
    }),
    queueCount() {
      return (
        this.jobs.length +
        this.issues.length +
        this.images.length +
        this.timelogs.length +
        this.approvals.length
      );
    },
  },
};
</script>

<style>
.tabbar-container {
  position: fixed;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 50px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background: #e1e4e6;
  z-index: 5;
}
</style>

<style scoped>
.grid {
  margin: 0;
}

.grid .row {
  padding-top: 5px;
}

.column {
  text-align: center;
}

.tabbar-item img {
  opacity: 0.54;
}

.tabbar-item .active img {
  opacity: 0.9;
}

.tabbar-item p {
  font-size: 11px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  line-height: 0.9rem;
}

.tabbar-item .active p {
  color: rgba(0, 0, 0, 0.9);
}

.tabbar-item .ui.label {
  top: -4px !important;
  padding: 3px 5px !important;
  border-radius: 4px !important;
}

.tabbar-item .ui.floating.label {
  left: 65% !important;
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .tabbar-container {
    height: 80px;
  }
}
</style>
