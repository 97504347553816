<template>
  <div>
    <!-- Navigation -->
    <NavBarJob title="Anmerkung senden" :job="job"></NavBarJob>

    <main class="ui container">
      <form class="ui form" @submit.prevent="saveRemark" v-if="job">
        <!-- File Input -->
        <FileCaptureImage
          :job="job"
          :remark="remark"
          entity="remark"
          required="true"
          v-on:captured-image="handleDraftImageSelection($event)"
          v-on:deleted-image="handleDraftImageDeletion($event)"
        ></FileCaptureImage>
        <!-- Description -->
        <div class="ui field">
          <label>Optionale Beschreibung</label>
          <p>Ergänze optional deine Anmerkung mit wenigen Worten.</p>
          <textarea
            class="multi"
            type="text"
            name="description"
            placeholder="Anmerkung hinzufügen…"
            spellcheck="true"
            autocorrect="on"
            rows="3"
            :value="remark.description"
            @blur="setDescription($event)"
          ></textarea>
        </div>
        <!-- Save Button -->
        <button
          class="ui fluid left labeled icon huge green button"
          type="submit"
          style="margin-top: 1.5em"
          :class="{ loading }"
        >
          <i class="save icon"></i>
          Anmerkung speichern
        </button>
      </form>
    </main>

    <!-- Modal Cancel -->
    <ModalCancel
      :isConfirmLeavingViewModalShowing.sync="isConfirmLeavingViewModalShowing"
      :isConfirmLeavingViewModalConfirmed.sync="
        isConfirmLeavingViewModalConfirmed
      "
    ></ModalCancel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavBarJob from "@/components/NavBarJob.vue";
import ModalCancel from "@/components/modal/ModalCancel.vue";
import FileCaptureImage from "@/components/form/FileCaptureImage.vue";
import { EventBus } from "@/event-bus";

export default {
  name: "Remark",
  components: {
    NavBarJob,
    FileCaptureImage,
    ModalCancel,
  },
  data() {
    return {
      loading: false,
      title: null,
      issueDescription: null,
      issueContinueSelection: null,
      cancelRoute: null,
      isConfirmLeavingViewModalShowing: false,
      isConfirmLeavingViewModalConfirmed: false,
      draftImages: [],
    };
  },
  computed: {
    ...mapState({
      job: state => state.job.current,
      remark: state => state.remark.current,
      user: state => state.user.current,
    }),
    description: {
      get() {
        if (!this.remark) {
          return null;
        }
        return this.remark.description;
      },
      set(newValue) {
        this.$store.dispatch("remark/updateCurrent", {
          payload: { description: newValue },
        });
      },
    },
  },
  watch: {
    // Fetch data if the route changes
    $route: "setCurrentRemark",
  },
  beforeRouteLeave(to, from, next) {
    // Show confirmation alert when user taps on the back button
    if (this.isConfirmLeavingViewModalConfirmed) {
      next();
    } else {
      this.showLeaveViewAlert(to);
      next(false);
    }
  },
  created() {
    // Get data
    this.setCurrentRemark();
  },
  mounted() {
    $(".ui.radio.checkbox").checkbox();

    // Handle cancel confirmation for leave modal
    EventBus.$once("cancelModalConfirmed", () => {
      this.isConfirmLeavingViewModalConfirmed = true;
      this.deleteDraftImages();

      this.$router.push({
        name:
          this.cancelRoute && this.cancelRoute.name
            ? this.cancelRoute.name
            : null,
        params: this.cancelRoute.params ? this.cancelRoute.params : null,
      });
    });
  },
  methods: {
    async setCurrentRemark() {
      await this.$store
        .dispatch("job/setCurrent", { id: this.$route.params.jobId })
        .then(() =>
          this.$store.dispatch("remark/setCurrent", {
            remark: this.remark,
          })
        );
    },
    setDescription(event) {
      this.$store.dispatch("remark/updateCurrent", {
        payload: { description: event.target.value },
      });
    },
    handleDraftImageSelection: function (event) {
      if (event && !this.draftImages.includes(event)) {
        this.draftImages.push(event);
      }
    },
    handleDraftImageDeletion: function (event) {
      if (event && this.draftImages.includes(event)) {
        this.draftImages.pop(event);
      }
    },
    deleteDraftImages() {
      this.draftImages.map(image => {
        this.$store.dispatch("image/delete", {
          fileName: image,
        });
      });
    },
    saveRemark() {
      this.isConfirmLeavingViewModalConfirmed = true;
      this.loading = true;

      this.$store
        .dispatch("remark/save", this.remark)
        .then(() =>
          this.$store.dispatch("job/update", {
            id: this.$route.params.jobId,
            payload: { sync_status: 0 },
          })
        )
        .then(() => {
          this.loading = false;
          EventBus.$emit("saved", "Anmerkung gespeichert");
          this.$store.dispatch("sync/start");
          this.$router.push({
            name: "job",
            params: { jobId: this.$route.params.jobId },
          });
        });
    },
    showLeaveViewAlert(to) {
      this.isConfirmLeavingViewModalShowing = true;
      this.isConfirmLeavingViewModalConfirmed = false;
      this.cancelRoute = to;
    },
  },
};
</script>

<style>
.ui.form textarea {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}
</style>
