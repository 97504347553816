<template>
  <button
    v-if="isVisible"
    class="ui fluid left labeled icon big button"
    @click="stopTimelog"
  >
    <i class="left stop icon"></i>
    Arbeit stoppen
  </button>
</template>

<script>
export default {
  name: "ButtonJobContinue",
  props: ["jobId", "userId", "isVisible", "compactMode"],
  methods: {
    stopTimelog() {
      if (!this.stopTimelog) {
        this.$store.dispatch("timelog/create", {
          jobId: this.jobId,
          userId: this.userId,
        });
      }
      this.$router.push({
        name: "timelogStop",
        params: {
          jobId: this.jobId,
          timelogId: this.startTimelog ? this.startTimelog.id : null,
        },
      });
    },
  },
};
</script>
