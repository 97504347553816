import RemarkService from "@/services/RemarkService.js";
import {
  REMARK_ADD,
  REMARK_DELETE,
  REMARK_DELETE_CURRENT_PICTURE,
  REMARK_RESET,
  REMARK_SAVE,
  REMARK_SET_CURRENT,
  REMARK_SET_CURRENT_ATTRIBUTE,
  REMARK_SET_CURRENT_PICTURE,
  REMARK_UPDATE,
  REMARK_UPDATE_CURRENT,
} from "@/store/mutation-types";
import store from "@/store/store";

export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  CLOSED: 2,
  SENT: 3,
});

function createNewRemark({ jobId, userId }) {
  return {
    id: new Date().getTime(),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    sync_status: 0,
    edit_status: 0,
    user_id: parseInt(userId),
    job_id: parseInt(jobId),
    images: null,
    description: null,
  };
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
  current: null,
};

/**
 * Getters
 */
export const getters = {
  current: state => state.current,
  all: state => state.list,
  pending: state =>
    state.list.filter(remark => remark && remark.sync_status !== 2),
};

/**
 * Actions
 */
export const actions = {
  create(_, { jobId, userId }) {
    return new Promise(async resolve => {
      const newRemark = await createNewRemark({
        jobId,
        userId,
      });
      resolve(newRemark);
    });
  },
  update({ commit }, { id, payload }) {
    return new Promise(resolve => {
      commit(REMARK_UPDATE, {
        id,
        payload,
      });
      resolve();
    });
  },
  updateCurrent({ commit }, { payload }) {
    return new Promise(resolve => {
      commit(REMARK_UPDATE_CURRENT, {
        payload,
      });
      resolve();
    });
  },
  save({ commit, state }, remark) {
    return new Promise(resolve => {
      // Add remark to list
      commit(REMARK_ADD, remark);

      // Get index of added remark
      const index = state.list.findIndex(element => element.id === remark.id);
      if (index === -1)
        throw Error(`Remark with id ${remark.id} not found in store`);

      commit(REMARK_SAVE, {
        remark,
        index,
      });
      resolve();
    });
  },
  /**
   * Sets the current remark
   */
  setCurrent({ commit, state }, { remarkId, remark }) {
    return new Promise(resolve => {
      let localRemark;
      if (!remark) {
        localRemark = state.list.find(
          element => element.id === remarkId.toString()
        );
        if (!localRemark)
          throw Error(`Remark with id ${remarkId} not found in store`);
      } else {
        localRemark = remark;
      }

      commit(REMARK_SET_CURRENT, localRemark);
      resolve(localRemark);
    });
  },
  /**
   * Sets the given attribute to the given value
   */
  setCurrentAttribute({ commit }, { fieldName, value }) {
    return new Promise(resolve => {
      commit(REMARK_SET_CURRENT_ATTRIBUTE, {
        fieldName,
        value,
      });
      resolve();
    });
  },
  setCurrentPicture({ commit, state }, { remarkId, fileName }) {
    return new Promise(resolve => {
      const remark = state.current;
      if (!remark) throw Error(`Issue with id ${remarkId} not found in store`);

      commit(REMARK_SET_CURRENT_PICTURE, {
        fileName,
      });
      resolve();
    });
  },
  deleteCurrentPicture({ commit, state }, { remarkId, fileName }) {
    return new Promise(resolve => {
      const remark = state.current;
      if (!remark) throw Error(`Remark with id ${remarkId} not found in store`);

      commit(REMARK_DELETE_CURRENT_PICTURE, {
        fileName,
      });
      resolve();
    });
  },
  /**
   * Resets all available data
   */
  reset({ commit }) {
    return new Promise(resolve => {
      commit(REMARK_RESET);
      resolve();
    });
  },
  uploadPending() {
    return new Promise((resolve, reject) => {
      const items = store.getters["remark/pending"];
      console.log("[remark/uploadPending] called", items);

      // Check if pending items are available
      if (!items || items.length === 0) {
        console.log("[remark/uploadPending] No pending items available");
        resolve();
      } else {
        // Upload responses
        items
          .reduce(
            (sequence, item) =>
              sequence.then(() => {
                console.log("[remark/uploadPending] Uploading item…", item);
                return store.dispatch("remark/upload", item);
              }),
            Promise.resolve()
          )
          .then(() => {
            console.log("[remark/uploadPending] Uploaded with success");
            resolve();
          })
          .catch(error => {
            console.error(
              "[remark/uploadPending] Error while uploading",
              error
            );
            reject(error);
          });
      }
    });
  },
  upload({ commit, dispatch }, remark) {
    return new Promise((resolve, reject) => {
      console.log("[remark/upload] called", remark);
      // Set sync state to pending
      dispatch("update", {
        id: remark.id,
        payload: {
          sync_status: 0,
        },
      })
        .then(() =>
          // Upload images for remark
          store.dispatch("image/uploadByReferenceId", {
            referenceId: remark.id,
          })
        )
        .then(() =>
          // Upload remark
          RemarkService.upload(remark)
        )
        .then(response => {
          if (response.status != 200 && response.status != 201)
            throw new Error("Error uploading remark");
          return response.data.data;
        })
        .then(() => {
          // Save sync state
          commit(REMARK_UPDATE, {
            id: remark.id,
            payload: {
              uploaded_at: new Date().toISOString(),
              sync_status: 2,
            },
          });
          resolve();
        })
        .then(() => resolve())
        .catch(error => {
          // Catch error and set remark to pending
          dispatch("update", {
            id: remark.id,
            payload: {
              sync_status: 0,
            },
          });
          reject(error);
        });
    });
  },
  cleanSynced({ dispatch }) {
    return new Promise(async resolve => {
      await dispatch("abstract/cleanSynced", { entity: "remark" });
      resolve();
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  [REMARK_ADD](state, remark) {
    state.list.push(remark);
  },
  [REMARK_UPDATE](state, { id, payload }) {
    const index = state.list.findIndex(element => element.id == id);
    if (index === -1) throw Error(`Remark with id ${id} not found in store`);

    let remark = state.list.find(element => element.id == id);
    remark = Object.assign(remark, payload);

    state.list[index] = remark;
  },
  [REMARK_UPDATE_CURRENT](state, { payload }) {
    state.current = Object.assign(state.current, payload);
  },
  [REMARK_SAVE](state, { remark, index }) {
    state.list.splice(index, 1, remark);
  },
  [REMARK_DELETE](state, { index }) {
    state.list.splice(index, 1);
  },
  [REMARK_SET_CURRENT](state, remark) {
    state.current = remark;
  },
  [REMARK_SET_CURRENT_ATTRIBUTE](state, { fieldName, value }) {
    state.current[fieldName] = value;
  },
  [REMARK_SET_CURRENT_PICTURE](state, { fileName }) {
    if (!state.current.images) {
      state.current.images = [];
    }
    state.current.images.push({
      fileName,
      url: null,
    });
  },
  [REMARK_DELETE_CURRENT_PICTURE](state, { fileName }) {
    const index = state.current.images.findIndex(
      element => element.fileName === fileName
    );
    if (index === -1)
      throw Error(`Image with fileName ${fileName} not found in issue`);

    state.current.images.splice(index);
  },
  [REMARK_RESET](state) {
    state.current = null;
    state.list = [];
  },
};
