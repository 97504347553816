<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import { EventBus } from "@/event-bus";

export default {
  name: "App",
  data() {
    return {
      attachedEventListeners: false,
    };
  },
  created() {
    this.addEventBusListener();

    if ("serviceWorker" in navigator) {
      if ("BroadcastChannel" in self) {
        // BroadcastChannel API supported!
        // eslint-disable-next-line no-undef
        const swListener = new BroadcastChannel("swListener");
        swListener.onmessage = event => {
          if (event.data && event.data.cmd === "installed") {
            this.showNewVersionAvailableToast();
          }
        };
      } else {
        // Wait for update message by service worker for new version
        self.addEventListener("message", evt => {
          if (evt.data && evt.data.cmd === "installed") {
            this.showNewVersionAvailableToast();
          }
        });
      }
    }
  },
  methods: {
    addEventBusListener() {
      if (this.attachedEventListeners) {
        return;
      }

      EventBus.$on("added", () => this.reloadStoreData());
      EventBus.$on("updated", () => this.reloadStoreData());
      EventBus.$on("deleted", () => this.reloadStoreData());
      EventBus.$on("autoStoppedJobs", numberOfJobs =>
        this.shopAutoStoppedJobsToast(numberOfJobs)
      );
      EventBus.$on("saved", message => this.showSavedToast(message));
      EventBus.$on("synced", () => {
        this.showSyncFinishedWithSuccessToast();
      });
      EventBus.$on("synced-error", () => {
        this.showSyncFinishedWithErrorToast();
      });
      this.attachedEventListeners = true;
    },
    showNewVersionAvailableToast() {
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      Vue.toasted.show(
        "Eine neue Version von Survello wird automatisch installiert.",
        {
          theme: "primary",
          position: "bottom-center",
          duration: null,
          fullWidth: true,
          className: "toast-update",
          action: {
            text: "Aktualisieren",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
              window.location.reload();
            },
          },
        }
      );
    },
    showSyncFinishedWithSuccessToast() {
      Vue.toasted.show("Warteschlange übertragen", {
        theme: "primary",
        position: "bottom-left",
        duration: 2000,
        fullWidth: false,
        type: "success",
        className: "toast-update",
      });
    },
    showSyncFinishedWithErrorToast() {
      Vue.toasted.show("Warteschlange nicht übertragen", {
        theme: "primary",
        position: "bottom-left",
        duration: 2000,
        fullWidth: false,
        type: "error",
        className: "toast-error",
      });
    },
    showSavedToast(message) {
      Vue.toasted.show(message, {
        theme: "primary",
        position: "bottom-left",
        duration: 2000,
        fullWidth: false,
        type: "success",
        className: "toast-update",
      });
    },
    shopAutoStoppedJobsToast(numberOfJobs) {
      Vue.toasted.show(
        `${
          numberOfJobs == 1 ? "Eine Aufgabe" : `${numberOfJobs} Aufgaben`
        } wurden nach über 11 Stunden Laufzeit automatisch gestoppt`,
        {
          theme: "primary",
          position: "bottom-left",
          duration: 10000,
          fullWidth: false,
          type: "success",
          className: "toast-info",
        }
      );
    },
  },
};
</script>

<style>
:root {
  --blue: #2c3e50;
  --orange: #f05f40;
  --green: #42b983;
  --light-gray: #aaaaaa;
  --red: #d0021b;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--blue);
}

body {
  margin: 0;
  padding-top: 68px;
  background-color: #f6f9fc;
  background: #f6f9fc;
}

main {
  padding-bottom: 1em;
}

/* Navigation */
.ui.menu {
  color: #ffffff;
  background: var(--orange);
  background-color: var(--orange);
}
.ui.container {
  max-width: 650px !important;
  padding-bottom: 80px;
}

/* Nav Bar */

nav {
  height: 55px;
  padding: 0 16px 0 16px;
}

nav span {
  display: block;
  position: relative;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  box-sizing: border-box;
  padding-top: 16px;
}

nav span.subtitle {
  font-size: 14px;
  padding-top: 4px !important;
  color: rgba(255, 255, 255, 0.54);
}

nav a {
  color: white !important;
}
nav a:hover {
  color: #ccc;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* Toast */

.toasted-container {
  bottom: 60px !important;
}
.toasted-container a {
  color: #000;
  text-align: center;
}
.toasted.primary {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 14px;
  margin-right: 14px;
  border-radius: 0.28571429rem !important;
  background: var(--green);
}
.toasted.primary.action {
  border-radius: 4px;
  background: var(--green);
}
.toast-update.primary.default {
  color: black;
  border-radius: 4px;
  background: var(--green);
}
.toast-update.primary.default a {
  color: lightgray;
}
.toast-green.primary.default {
  color: white;
  border-radius: 4px;
  background: #7ebb3b;
}
.toast-error.primary.default {
  color: white;
  border-radius: 4px;
  background: var(--red);
}
.toast-info.primary.success {
  color: white;
  border-radius: 4px;
  background: var(--orange);
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
  only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .toasted-container {
    bottom: 100px !important;
  }
}

/* Fonts */

.ui.form .field > label,
.ui.form .grouped.fields > label {
  font-size: 1.1rem !important;
}

.deactive {
  color: var(--light-gray) !important;
}

.hidden {
  display: none !important;
}

/* Fields */
input[type="time"],
.ui.form input[type="time"] {
  display: block !important;
  min-height: 38px !important;
}
</style>
