<template lang="html">
  <div>
    <NavBarJobs></NavBarJobs>
    <main class="ui container">
      <div v-if="this.currentProject">
        <router-link
          to="/projects"
          class="ui blue large right labeled icon button fluid"
          style="margin-bottom: 20px; padding-bottom: 17px; text-align: left"
        >
          <i class="right arrow icon"></i>
          <span style="font-size: 12px; color: #b2deff">Aktives Projekt</span
          ><br />
          {{ this.currentProject.title }}
        </router-link>
      </div>

      <div
        v-if="!jobs || Object.keys(jobs).length === 0"
        class="ui container center aligned"
        style="padding-top: 3em"
      >
        <img src="./../assets/icon-inquiries-empty.png" width="75px" />
        <h3 class="deactive">Keine offenen Aufgaben</h3>
        <p class="deactive">
          Sobald offene Aufgaben zur Verfügung stehen, werden diese hier
          angezeigt.
        </p>
      </div>
      <div v-else-if="activeJobs && activeJobs.length > 0">
        <h5 style="color: gray">Aktive Aufgaben</h5>
        <div
          v-for="activeJob in activeJobs"
          :key="`${activeJob.id}-${activeJob.updated_at}`"
        >
          <CardJob :job="activeJob" :user="currentUser"></CardJob>
        </div>
      </div>
      <div v-if="inactiveJobs && inactiveJobs.length > 0">
        <h5 style="color: gray">Alle Aufgaben</h5>
        <div
          v-for="inactiveJob in inactiveJobs"
          :key="`${inactiveJob.id}-${inactiveJob.updated_at}`"
        >
          <CardJob :job="inactiveJob" :user="currentUser"></CardJob>
        </div>
      </div>
    </main>
    <TabBar></TabBar>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NavBarJobs from "@/components/NavBarJobs.vue";
import TabBar from "@/components/TabBar.vue";
import CardJob from "@/components/CardJob.vue";

export default {
  name: "Jobs",
  components: {
    NavBarJobs,
    TabBar,
    CardJob,
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current,
    }),
    ...mapGetters({
      allJobs: "job/all",
      allJobsByProjectId: "job/allByProjectId",
      activeJobsByProjectId: "job/activeByProjectId",
      inactiveJobsByProjectId: "job/inactiveByProjectId",
      active: "job/active",
      currentProject: "project/current",
      inactive: "job/inactive",
      fullName: "user/fullName",
    }),
    jobs() {
      if (this.currentProject) {
        return this.allJobsByProjectId(this.currentProject.id);
      } else {
        return this.allJobs;
      }
    },
    activeJobs() {
      if (this.currentProject) {
        return this.activeJobsByProjectId(this.currentProject.id);
      } else {
        return this.active;
      }
    },
    inactiveJobs() {
      if (this.currentProject) {
        return this.inactiveJobsByProjectId(this.currentProject.id);
      } else {
        return this.inactive;
      }
    },
  },
  data() {
    return {
      isVisibilityListenerSet: null,
    };
  },
  mounted() {
    if (!this.isVisibilityListenerSet) {
      this.setVisibility();
    }
    // Send user to project selection if no project is active
    if (process.env.VUE_APP_JOBPOOL_ACTIVE && !this.currentProject) {
      this.$router.push({
        path: "/projects",
      });
    }
  },
  methods: {
    setVisibility() {
      this.isVisibilityListenerSet = true;

      let standalone;
      if (navigator.standalone) {
        standalone = "standalone-ios";
      }
      if (window.matchMedia("(display-mode: standalone)").matches) {
        standalone = "standalone";
      }

      const getState = () => {
        if (document.visibilityState === "hidden") {
          return "hidden";
        }
        if (document.hasFocus()) {
          return "active";
        }
        return "passive";
      };

      let displayState = getState();

      const onDisplayStateChange = () => {
        const nextState = getState();
        const prevState = displayState;

        if (nextState !== prevState) {
          // console.log(`State changed: ${prevState} >>> ${nextState}`);
          displayState = nextState;

          //standalone will restrict to only running for an installed PWA on mobile
          if (nextState === "active") {
            //The app/browser tab has just been made active and is visible to the user
            //do whatever you want in here to update dynamic content, call api etc
            this.$store.dispatch("job/stopActiveJobs", {
              userId: this.currentUser.id,
            });
          }
        }
      };

      ["pageshow", "focus", "blur", "visibilitychange", "resume"].forEach(
        type => {
          window.addEventListener(type, onDisplayStateChange, {
            capture: true,
          });
        }
      );
    },
  },
};
</script>
