<template>
  <div class="ui container center aligned" v-if="job && job.edit_status === 0">
    <!-- No timelog message -->
    <div class="ui message" v-if="!startTimelog && !stopTimelog">
      <h1>Keine Zeit erfasst</h1>
      <span>00:00 Stunden</span>
    </div>
    <!-- Only start timelog message -->
    <div
      class="ui message"
      :class="{ green: !job.paused_at, blue: job.paused_at }"
      v-else-if="(startTimelog && !stopTimelog) || job.paused_at"
    >
      <h1>Seit {{ startTimelog.event_time | moment("HH:mm") }} Uhr</h1>
      <span v-if="!job.paused_at">Seit {{ currentDuration }}</span>
      <span v-else>Pausiert seit {{ pauseDuration }}</span>
    </div>
    <!-- Start & stop timelog message -->
    <div
      class="ui message"
      v-else-if="
        startTimelog &&
        stopTimelog &&
        startTimelog.start_id &&
        stopTimelog.start_id
      "
    >
      <h1>
        {{ startTimelog.event_time | moment("HH:mm") }} -
        {{ stopTimelog.event_time | moment("HH:mm") }} Uhr
      </h1>
      <span>{{ fullDuration }} Stunden</span>
    </div>
  </div>

  <!-- Confirmed task info -->
  <div
    class="ui container center aligned"
    v-else-if="job && job.edit_status === 1 && job.approved_at"
  >
    <div class="ui message">
      <i class="left check big icon"></i>
      <h2>Aufgabe intern fertig gemeldet</h2>
      <span>
        Diese Aufgabe wurde am {{ job.approved_at | moment("DD.MM.YYYY") }} um
        {{ job.approved_at | moment("HH:mm") }} Uhr intern als fertig gemeldet.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimerStatus",
  props: ["job", "user", "startTimelog", "stopTimelog"],
  asyncComputed: {
    currentDuration: {
      get() {
        if (this.startTimelog) {
          const startMoment = this.$moment(this.startTimelog.event_time);
          const stopMoment = this.$moment();
          const duration = this.$moment.duration(stopMoment.diff(startMoment));
          return duration.humanize();
        }
        return null;
      },
    },
    pauseDuration: {
      get() {
        if (this.job && this.job.paused_at) {
          const startMoment = this.$moment(this.job.paused_at);
          const stopMoment = this.$moment();
          const duration = this.$moment.duration(stopMoment.diff(startMoment));
          return duration.humanize();
        }
        return null;
      },
    },
    fullDuration: {
      get() {
        if (
          this.startTimelog &&
          this.stopTimelog &&
          this.startTimelog.start_id &&
          this.stopTimelog.start_id
        ) {
          const startMoment = this.$moment(this.startTimelog.event_time);
          const stopMoment = this.$moment(this.stopTimelog.event_time);
          const duration = this.$moment.duration(stopMoment.diff(startMoment));
          return this.$moment.utc(duration.asMilliseconds()).format("HH:mm");
        }
        return null;
      },
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 5px !important;
  font-weight: 500 !important;
}

.ui.message {
  background-color: transparent;
}
.ui.message.green {
  background-color: #fcfff580;
}
</style>
