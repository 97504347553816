<template>
  <div class="ui field" :class="{ required }">
    <label>Foto</label>
    <p>Erfasse ein Foto über die untere Schaltfläche.</p>
    <!-- Preview Grid -->
    <div class="ui grid" v-if="filteredImages && filteredImages.length > 0">
      <div
        class="ui eight wide column"
        v-for="image in filteredImages"
        :v-bind="image.name"
        :key="image.id"
      >
        <img
          class="ui small rounded square image image-preview"
          :name="image.name"
          :src="getSourceForImage(image)"
        />
        <div
          class="ui fluid icon red button"
          @click="showDeletePhotoModal(image)"
        >
          <i class="trash icon"></i>
        </div>
      </div>
    </div>
    <!-- Placeholder Grid -->
    <div v-else class="ui grid">
      <div class="ui eight wide column">
        <img
          class="ui tiny rounded square image image-preview"
          src="@/assets/white-image.png"
        />
      </div>
    </div>
    <!-- Capture Button -->
    <div
      class="ui field"
      style="margin-top: 1em"
      v-if="!filteredImages || (filteredImages && filteredImages.length < 1)"
      :class="{
        disabled: filteredImages && filteredImages.length >= 1,
        required: required,
      }"
    >
      <input
        type="file"
        accept="image/*"
        @change="generatePreviewImage"
        style="z-index: 2"
        capture
        :required="required"
      />
      <div class="ui fluid left labeled icon big blue button">
        <i class="camera icon"></i>
        Foto aufnehmen
      </div>
    </div>
    <!-- Delete Photo Modal -->
    <ModalImageDelete
      :fileName="deletePhotoFileName"
      :isDeletePhotoModalShowing.sync="isDeletePhotoModalShowing"
      :isDeletePhotoModalConfirmed.sync="isDeletePhotoModalConfirmed"
    ></ModalImageDelete>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import { dataURItoBlob } from "@/utils/helper";
import ModalImageDelete from "@/components/modal/ModalImageDelete.vue";

const md5 = require("md5");
const placeholderImage = require("@/assets/white-image.png");

export default {
  name: "FileCaptureImage",
  components: {
    ModalImageDelete,
  },
  props: [
    "job",
    "timelog",
    "approval",
    "issue",
    "remark",
    "entity",
    "required",
  ],
  data() {
    return {
      isPreviewImageUpdatedNeeded: true,
      previewFileName: null,
      deletePhotoFileName: null,
      isDeletePhotoModalShowing: false,
      isDeletePhotoModalConfirmed: true,
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "current",
    }),
    ...mapGetters("image", {
      images: "all",
    }),
    filteredImages() {
      if (!this.images) {
        return [];
      }
      const images = this.images.filter(image => {
        // eslint-disable-next-line
        if (
          this.entity === "timelog" &&
          this.timelog &&
          image.referenceId === this.timelog.id
        ) {
          return image;
        }
        if (
          this.entity === "approval" &&
          this.approval &&
          image.referenceId === this.approval.id
        ) {
          return image;
        }
        if (
          this.entity === "issue" &&
          this.issue &&
          image.referenceId === this.issue.id
        ) {
          return image;
        }
        if (
          this.entity === "remark" &&
          this.remark &&
          image.referenceId === this.remark.id
        ) {
          return image;
        }
        return null;
      });
      return images;
    },
  },
  mounted() {
    EventBus.$on("SHOW_DELETEPHOTO_MODAL", image => {
      this.isDeletePhotoModalShowing = true;
      this.isDeletePhotoModalConfirmed = false;
      this.deletePhotoFileName = image.fileName;
    });

    EventBus.$on("SHOW_DELETEPHOTO_MODAL_CONFIRMED", fileName => {
      this.$store
        .dispatch("image/delete", {
          fileName,
        })
        .then(() => {
          if (this.entity === "timelog") {
            this.$store.dispatch("timelog/deleteCurrentPicture", {
              timelogId: this.timelog.id,
              fileName,
            });
          } else if (this.entity === "issue") {
            this.$store.dispatch("issue/deleteCurrentPicture", {
              issueId: this.issue.id,
              fileName,
            });
          } else if (this.entity === "approval") {
            this.$store.dispatch("approval/deleteCurrentPicture", {
              approvalId: this.approval.id,
              fileName,
            });
          } else if (this.entity === "remark") {
            this.$store.dispatch("remark/deleteCurrentPicture", {
              remarkId: this.remark.id,
              fileName,
            });
          }
          this.$emit("deleted-image", fileName);
        });
    });
  },
  methods: {
    showCapturePhotoModal() {
      EventBus.$emit("SHOW_CAPTUREPHOTO_MODAL");
    },
    showDeletePhotoModal(image) {
      EventBus.$emit("SHOW_DELETEPHOTO_MODAL", image);
    },
    getSourceForImage(image) {
      return this.loadImage(image);
    },
    /* eslint-disable consistent-return */
    loadImage(image) {
      // Display image from remote url if available
      const localImage = this.images.find(
        _image => _image.fileName === image.fileName
      );

      // Display image from remote url if available
      if (localImage && localImage.url && window.navigator.onLine) {
        return localImage.url;
      }

      // Show placeholder when no image is available
      if (!localImage || (!localImage.fileName && !localImage.url)) {
        return placeholderImage;
      }

      // Check if local image is Blob or DataURI
      let localImageData = localImage.src;
      if (!(localImageData instanceof Blob)) {
        localImageData = dataURItoBlob(localImage.src);
      }

      // Create object url from image data and return value
      const previewImage = new Image();
      previewImage.src = URL.createObjectURL(localImageData);
      previewImage.onload = () => {
        URL.revokeObjectURL(this.src);
      };
      return previewImage.src;
    },
    generatePreviewImage() {
      const file = document.querySelector(`input[type=file]`).files[0];
      // Generate file name and set referenceId based on given entity
      let referenceId;
      let fileName;
      let fileExtension = file.name.split(".").pop();

      if (this.entity === "timelog") {
        referenceId = this.timelog.id;
        fileName = `${md5(new Date().getTime())}-${this.timelog.job_id}-${
          this.user.id
        }.${fileExtension}`;
      } else if (this.entity === "issue") {
        referenceId = this.issue.id;
        fileName = `${md5(new Date().getTime())}-${this.issue.job_id}-${
          this.user.id
        }.${fileExtension}`;
      } else if (this.entity === "remark") {
        referenceId = this.remark.id;
        fileName = `${md5(new Date().getTime())}-${this.remark.job_id}-${
          this.user.id
        }.${fileExtension}`;
      } else if (this.entity === "approval") {
        referenceId = this.approval.id;
        fileName = `${md5(new Date().getTime())}-${this.approval.job_id}-${
          this.user.id
        }.${fileExtension}`;
      }

      console.log("[generatePreviewImage] entity", this.entity);
      console.log("[generatePreviewImage] fileName", fileName);

      this.$store
        .dispatch("image/add", {
          file,
          fileName,
          referenceId,
          referenceEntity: this.entity,
        })
        .then(() => {
          this.$emit("captured-image", fileName);

          if (this.entity === "timelog") {
            this.$store.dispatch("timelog/setCurrentPicture", {
              timelogId: this.timelog.id,
              fileName,
            });
          } else if (this.entity === "issue") {
            this.$store.dispatch("issue/setCurrentPicture", {
              issueId: this.issue.id,
              fileName,
            });
          } else if (this.entity === "remark") {
            this.$store.dispatch("remark/setCurrentPicture", {
              remarkId: this.remark.id,
              fileName,
            });
          } else if (this.entity === "approval") {
            this.$store.dispatch("approval/setCurrentPicture", {
              approvalId: this.approval.id,
              fileName,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.btn-photo-remove {
  margin-top: 16px !important;
}

input[type="file"] {
  position: absolute;
  overflow: hidden;
  opacity: 0.01;
  background-color: red;
  max-width: 100%;
  z-index: 1;
}

.image-preview {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  width: 100% !important;
}

.photo-preview__buttons {
  padding-bottom: 10px !important;
}

.ui.segment {
  padding: 0;
  box-shadow: none;
  border: none;
}

.ui.segment > .ui.dimmer {
  z-index: 1;
}
</style>
