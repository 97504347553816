import ProjectService from "@/services/ProjectService.js";
import {
  PROJECTS_ADD,
  PROJECTS_DELETE,
  PROJECTS_RESET,
  PROJECTS_SET_CURRENT,
  PROJECTS_UNSET_CURRENT,
  PROJECTS_UPDATE,
} from "@/store/mutation-types";
import _ from "lodash";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
  current: null,
};

/**
 * Getters
 */
export const getters = {
  all: state => _.sortBy(state.list, ["title"]),
  current: state => state.current,
  getById: state => id =>
    state.list.find(project => {
      return project.id === id;
    }),
};

/**
 * Actions
 */
export const actions = {
  update({ commit }, { id, payload }) {
    commit(PROJECTS_UPDATE, {
      id,
      payload,
    });
  },

  delete({ commit, state }, { projectId }) {
    const index = state.list.findIndex(element => element.id === projectId);
    if (index === -1)
      throw Error(`Project with id ${jobId} not found in store`);

    commit(PROJECTS_DELETE, {
      index,
    });
  },

  setCurrent({ commit }, { id }) {
    return new Promise(resolve => {
      commit(PROJECTS_SET_CURRENT, {
        id,
      });
      resolve();
    });
  },

  reset({ commit }) {
    return new Promise(resolve => {
      commit(PROJECTS_RESET);
      resolve();
    });
  },

  async fetchAll({ commit, dispatch }, credentials) {
    return Promise.resolve()
      .then(() => ProjectService.fetchAll(credentials))
      .then(async response => {
        const projects = response.data.data;
        projects.map(project => {
          commit(PROJECTS_ADD, project);
        });
        await dispatch("deletePruned", {
          remoteProjects: projects,
        });
      })
      .catch(error => {
        console.error("Error on fetching all projects", error.stack || error);
        throw error;
      });
  },

  async deletePruned({ state, dispatch }, { remoteProjects }) {
    return Promise.resolve().then(() => {
      state.list.forEach(async dbProject => {
        const isLocalProjectAvailableOnServer = remoteProjects.find(
          project => project.id === dbProject.id
        );
        if (!isLocalProjectAvailableOnServer) {
          await dispatch("delete", {
            projectId: dbProject.id,
          });
        }
      });
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  [PROJECTS_ADD](state, project) {
    const index = state.list.findIndex(element => element.id == project.id);
    if (index === -1) {
      project.updated_at = new Date().toISOString();
      state.list.push(project);
    } else {
      let localProject = state.list.find(_project => _project.id == project.id);
      if (!localProject)
        throw Error(`Project with id ${project.id} not found in store`);

      // Skip completed jobs from local storage
      if (localProject && localProject.edit_status > 0) {
        return;
      }
      // Overwrite local job
      localProject = Object.assign(localProject, project);

      state.list[index] = localProject;
    }
  },

  [PROJECTS_DELETE](state, { index }) {
    state.list.splice(index, 1);
  },

  [PROJECTS_SET_CURRENT](state, { id }) {
    const project = state.list.find(element => element.id == id);
    if (!project) throw Error(`Project with id ${id} not found in store`);

    state.current = project;
  },

  [PROJECTS_UNSET_CURRENT](state) {
    state.current = null;
  },

  [PROJECTS_RESET](state) {
    state.current = null;
    state.list = [];
  },
};
