import axios from "axios";
import store from "@/store/store";
import router from "@/router";

let sharedApiClient;

export const apiClient = {
  sharedInstance() {
    if (!sharedApiClient) {
      // Create shared axios instance
      sharedApiClient = axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL,
        withCredentials: false,
        headers: {
          Accept: "application/json, *!/!*",
          "Content-Type": "application/json",
        },
        timeout: 30000,
      });

      sharedApiClient.interceptors.response.use(
        response => response,
        async error => {
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            // Remove user from local store when api returns 401
            await store.dispatch("user/logoutWithoutReset").then(() =>
              router.push({
                name: "login",
                query: {
                  sessionInvalid: true,
                },
              })
            );
          }
          return Promise.reject(error);
        }
      );
    }

    // Set bearer token in authorization header if user is logged in
    const loggedInUser = store.getters["user/current"];
    if (loggedInUser && loggedInUser.api_token) {
      sharedApiClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${loggedInUser.api_token}`;
    }

    return sharedApiClient;
  },
};
