export const USERS_ADD = "USERS_ADD";
export const USERS_SET_CURRENT = "USERS_SET_CURRENT";
export const USERS_RESET = "USERS_RESET";

export const JOBS_ADD = "JOBS_ADD";
export const JOBS_UPDATE = "JOBS_UPDATE";
export const JOBS_SET_CURRENT = "JOBS_SET_CURRENT";
export const JOBS_SET_EDITSTATUS = "JOBS_SET_EDITSTATUS";
export const JOBS_SET_ACTIVE = "JOBS_SET_ACTIVE";
export const JOBS_DELETE = "JOBS_DELETE";
export const JOBS_RESET = "JOBS_RESET";

export const PROJECTS_ADD = "PROJECTS_ADD";
export const PROJECTS_UPDATE = "PROJECTS_UPDATE";
export const PROJECTS_SET_CURRENT = "PROJECTS_SET_CURRENT";
export const PROJECTS_UNSET_CURRENT = "PROJECTS_UNSET_CURRENT";
export const PROJECTS_DELETE = "PROJECTS_DELETE";
export const PROJECTS_RESET = "PROJECTS_RESET";

export const IMAGES_ADD = "IMAGES_ADD";
export const IMAGES_DELETE = "IMAGES_DELETE";
export const IMAGES_RESET = "IMAGES_RESET";
export const IMAGES_SET_ATTRIBUTE = "IMAGES_SET_ATTRIBUTE";
export const IMAGES_SET_IMAGE_URL_IN_ENTITY = "IMAGES_SET_IMAGE_URL_IN_ENTITY";

export const TIMELOG_ADD = "TIMELOG_ADD";
export const TIMELOG_UPDATE = "TIMELOG_UPDATE";
export const TIMELOG_UPDATE_CURRENT = "TIMELOG_UPDATE_CURRENT";
export const TIMELOG_SAVE = "TIMELOG_SAVE";
export const TIMELOG_DELETE = "TIMELOG_DELETE";
export const TIMELOG_RESET = "TIMELOG_RESET";
export const TIMELOG_SET_CURRENT = "TIMELOG_SET_CURRENT";
export const TIMELOG_SET_CURRENT_ATTRIBUTE = "TIMELOG_SET_CURRENT_ATTRIBUTE";
export const TIMELOG_SET_CURRENT_PICTURE = "TIMELOG_SET_CURRENT_PICTURE";
export const TIMELOG_DELETE_CURRENT_PICTURE = "TIMELOG_DELETE_CURRENT_PICTURE";

export const APPROVAL_ADD = "APPROVAL_ADD";
export const APPROVAL_UPDATE = "APPROVAL_UPDATE";
export const APPROVAL_UPDATE_CURRENT = "APPROVAL_UPDATE_CURRENT";
export const APPROVAL_SAVE = "APPROVAL_SAVE";
export const APPROVAL_DELETE = "APPROVAL_DELETE";
export const APPROVAL_RESET = "APPROVAL_RESET";
export const APPROVAL_SET_CURRENT = "APPROVAL_SET_CURRENT";
export const APPROVAL_SET_CURRENT_ATTRIBUTE = "APPROVAL_SET_CURRENT_ATTRIBUTE";
export const APPROVAL_SET_CURRENT_PICTURE = "APPROVAL_SET_CURRENT_PICTURE";
export const APPROVAL_DELETE_CURRENT_PICTURE =
  "APPROVAL_DELETE_CURRENT_PICTURE";

export const ISSUE_ADD = "ISSUE_ADD";
export const ISSUE_UPDATE = "ISSUE_UPDATE";
export const ISSUE_UPDATE_CURRENT = "ISSUE_UPDATE_CURRENT";
export const ISSUE_SAVE = "ISSUE_SAVE";
export const ISSUE_DELETE = "ISSUE_DELETE";
export const ISSUE_RESET = "ISSUE_RESET";
export const ISSUE_SET_CURRENT = "ISSUE_SET_CURRENT";
export const ISSUE_SET_CURRENT_ATTRIBUTE = "ISSUE_SET_CURRENT_ATTRIBUTE";
export const ISSUE_SET_CURRENT_PICTURE = "ISSUE_SET_CURRENT_PICTURE";
export const ISSUE_DELETE_CURRENT_PICTURE = "ISSUE_DELETE_CURRENT_PICTURE";

export const REMARK_ADD = "REMARK_ADD";
export const REMARK_UPDATE = "REMARK_UPDATE";
export const REMARK_UPDATE_CURRENT = "REMARK_UPDATE_CURRENT";
export const REMARK_SAVE = "REMARK_SAVE";
export const REMARK_DELETE = "REMARK_DELETE";
export const REMARK_RESET = "REMARK_RESET";
export const REMARK_SET_CURRENT = "REMARK_SET_CURRENT";
export const REMARK_SET_CURRENT_ATTRIBUTE = "REMARK_SET_CURRENT_ATTRIBUTE";
export const REMARK_SET_CURRENT_PICTURE = "REMARK_SET_CURRENT_PICTURE";
export const REMARK_DELETE_CURRENT_PICTURE = "REMARK_DELETE_CURRENT_PICTURE";

export const SYNC_SET_STATUS = "SYNC_SET_STATUS";
