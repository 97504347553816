<template>
  <div>
    <nav class="ui top fixed menu two column grid wide">
      <div class="column menu-title__column">
        <span class="menu-title">
          Diagnose
          <br />
        </span>
      </div>
      <div class="column menu__add" @click="closeView">
        <button
          class="ui icon button menu__close-button right floated inverted"
        >
          <i class="remove icon"></i>
        </button>
      </div>
    </nav>
    <main class="ui container">
      <div class="ui cards">
        <div class="card fluid" v-if="this.storageUsage">
          <div class="content">
            <div class="header">
              <h4>Speichernutzung</h4>
            </div>
            <div class="description">
              {{ this.storageUsage }} ({{ this.storageUsagePercent }} %) von
              verfügbaren {{ this.storageQuota }} genutzt.
            </div>
          </div>
          <div class="extra content">
            <div class="ui progress bottom success" data-total="100">
              <div
                class="bar"
                v-bind:style="{ width: storageUsagePercent + '%' }"
              >
                <div class="progress"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="card fluid" v-if="activeUser">
          <div class="content">
            <div class="header">
              <h4>Datenbank importieren</h4>
            </div>
            <div class="description">
              Über diese Option können Sie eine Datenbank wiederherstellen.
            </div>
          </div>
          <div id="dropzone" @dragover="onDragOver" @drop="onDrop">
            Drop dexie export JSON file here
          </div>
        </div>
        <div class="card fluid" v-if="activeUser">
          <div class="content">
            <div class="header">
              <h4>Warteschlange übertragen</h4>
            </div>
            <div class="description">
              Falls die Warteschlange nicht vollständig übertragen wurde, kann
              der Vorgang hier erneut gestartet werden.
              <button
                class="fluid ui button"
                v-bind:class="{ disabled: isSyncing, loading: isSyncing }"
                style="margin-top: 15px"
                @click="startSync"
              >
                <i class="left cloud upload icon"></i>
                Warteschlange übertragen
              </button>
            </div>
          </div>
        </div>
        <div class="card fluid">
          <div class="content">
            <div class="header">
              <h4>Datenbank exportieren</h4>
            </div>
            <div class="description">
              Falls Sie vom Support aufgefordert werden die Datenbank zu
              exportieren, wählen Sie bitte diese Option bei bestehender
              Internetverbindung.
            </div>
          </div>
          <div class="extra content" @click="exportDatabase">
            <div
              class="ui button fluid"
              id="btn-export-database"
              :class="{ disabled: isExportRunning }"
            >
              <i class="browser icon"></i>
              {{
                this.exportLabel ? this.exportLabel : "Datenbank exportieren"
              }}
            </div>
            <div class="ui message success" v-if="exportProgress === 100">
              <p>Datenbank erfolgreich exportiert und übertragen.</p>
            </div>
            <div class="ui message error" v-if="exportError">
              <p>
                Bei der Übertragung der Datenbank ist ein Fehler aufgetreten.
              </p>
              <p>
                <strong>{{ exportError.message }}</strong>
              </p>
            </div>
            <div
              class="ui progress bottom success"
              data-total="100"
              style="margin-top: 1em"
              v-if="isExportRunning"
            >
              <div class="bar" v-bind:style="{ width: exportProgress + '%' }">
                <div class="progress">{{ this.exportProgress }} %</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card fluid" v-if="activeUser && activeUser.id === 1000">
          <div class="content">
            <div class="header">
              <h4>Datenbank importieren</h4>
            </div>
            <div class="description">
              Über diese Option können Sie eine Datenbank wiederherstellen.
            </div>
          </div>
          <div id="dropzone" @dragover="onDragOver" @drop="onDrop">
            Drop dexie export JSON file here
          </div>
        </div>
        <div class="card fluid" v-if="1 == 3">
          <div class="content">
            <div class="header">
              <h4>Datenbank testen</h4>
            </div>
            <div class="description">
              Falls Sie vom Support aufgefordert werden die Datenbank zu testen,
              wählen Sie bitte diese Option bei bestehender Internetverbindung.
            </div>
          </div>
          <div class="extra content" @click="initTestDatabase">
            <div class="ui button fluid" id="btn-test-database">
              <i class="browser icon"></i> Datenbank testen
            </div>
          </div>
          <div
            class="extra content"
            v-if="errorImages && errorImages.length > 0"
          >
            <div class="ui warning message fluid">
              <div class="header">Fehlerhafte Bilder</div>
              <div class="ui list">
                <div
                  class="item"
                  v-for="skippedImage in errorImages"
                  :key="skippedImage"
                  style="word-break: break-word"
                >
                  {{ skippedImage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card fluid" v-if="1 == 3">
          <div class="content">
            <div class="header">
              <h4>Datenbank loggen</h4>
            </div>
            <div class="description">
              Falls Sie vom Support aufgefordert werden die Datenbank zu testen,
              wählen Sie bitte diese Option bei bestehender Internetverbindung.
            </div>
          </div>
          <div class="extra content" @click="logDatabase">
            <div class="ui button fluid" id="btn-test-database">
              <i class="browser icon"></i> Datenbank loggen
            </div>
          </div>
        </div>
        <div class="card fluid" v-if="isAndroid">
          <div class="content">
            <div class="header">
              <h4>TeamViewer herunterladen</h4>
            </div>
            <div class="description">
              Falls Sie vom Support aufgefordert werden die Teamviewer-App zu
              laden, leitet dieser Link zum Google Store Play Store weiter.
            </div>
          </div>
          <div class="extra content" @click="openTeamViewerApp">
            <a
              class="ui button fluid"
              href="https://play.google.com/store/apps/details?id=com.teamviewer.teamviewer.market.mobile"
              target="_blank"
            >
              <i class="google play icon"></i> App herunterladen
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Dexie from "dexie";
import { exportDB, peakImportFile } from "dexie-export-import";
import {
  uploadFilePart,
  getFileNameForDbExport,
} from "@/services/DatabaseExportService";
import getVersionFromManifest from "@/utils/helper";
import { formatBytes, formatPercentage } from "@/utils/filters";
import { importDB } from "dexie-export-import";

function progressCallback({ totalRows, completedRows }) {
  console.log(`Progress: ${completedRows} of ${totalRows} rows completed`);
}

export default {
  name: "SettingsDiagnosis",
  mounted() {
    this.getCurrentDataUsage();
    this.version = getVersionFromManifest();
  },
  data() {
    return {
      loading: false,
      isExportRunning: false,
      exportLabel: null,
      exportProgress: null,
      exportError: null,
      errorCounter: 0,
      errorImages: [],
      error: null,
      online: false,
      version: null,
      storageUsage: null,
      storageUsagePercent: null,
      storageQuota: null,
    };
  },
  computed: {
    ...mapGetters({
      activeUser: "user/current",
      userIsAdmin: "user/isAdmin",
    }),
    ...mapState({
      currentUser: state => state.user.current,
      isSyncing: state => state.sync.isActive,
    }),
    isAndroid() {
      return /(android)/i.test(navigator.userAgent);
    },
  },
  methods: {
    getCurrentDataUsage() {
      if ("storage" in navigator && "estimate" in navigator.storage) {
        // Get current storage values
        navigator.storage.estimate().then(estimate => {
          this.storageUsage = formatBytes(estimate.usage);
          this.storageQuota = formatBytes(estimate.quota);
          this.storageUsagePercent = formatPercentage(
            estimate.usage,
            estimate.quota
          );
        });
      }
    },
    openTeamViewerApp() {},
    startSync() {
      this.$store.dispatch("sync/start");
    },

    initTestDatabase() {
      console.log("[DB Test] Init test database called…");

      this.errorCounter = 0;
      skippedImages = [];
      this.errorImages = [];
      this.testDatabase();
    },
    /*
    testDatabase() {
      console.log("[DB Test] Test database called…");
      console.log("[DB Test] Skipped images", skippedImages);
      let currentKey;

      getDbConnection()
        .then(db => {
          const tx = db.transaction("image", "readonly");
          const store = tx.objectStore("image");
          return store.openCursor();
        })
        .then(function logItems(cursor) {
          if (!cursor) {
            return;
          }
          if (cursor && skippedImages && skippedImages.includes(cursor.key)) {
            console.log("[DB Test] Skipped already failed object");
            return;
          }
          currentKey = cursor.key;
          console.log("[DB Test] Cursor is at key: ", cursor.key);
          console.log("[DB Test] Cursor is at value: ", cursor.value);
          try {
            for (const field in cursor.value) {
              console.log(cursor.value[field]);
            }
          } catch (e) {
            throw Error(e);
          }

          return cursor.continue().then(logItems);
        })
        .then(() => {
          console.log("[DB Test] Done!");
          this.errorImages = skippedImages;
        })
        .catch(error => {
          console.error("[DB Test] Error on db test", error);
          console.error(error);
          if (currentKey) {
            skippedImages.push(currentKey);
          }

          this.errorCounter++;

          if (this.errorCounter < 50) {
            this.testDatabase();
          }
        });
    },
    async logDatabase() {
      await this.performLogDatabase("user");
      await this.performLogDatabase("inquiry");
      await this.performLogDatabase("job");
      await this.performLogDatabase("response");
      await this.performLogDatabase("image");
    },
    performLogDatabase(entity) {
      console.log("[DB Logging] Log database called with entity", entity);
      let currentKey;

      return getDbConnection()
        .then(db => {
          const tx = db.transaction(entity, "readonly");
          const store = tx.objectStore(entity);
          return store.openCursor();
        })
        .then(function logItems(cursor) {
          if (!cursor) {
            return;
          }
          if (
            cursor &&
            skippedLogObjects &&
            skippedLogObjects.includes(cursor.key)
          ) {
            console.log("[DB Logging] Skipped already failed object");
            return;
          }
          currentKey = cursor.key;
          console.log("[DB Logging] Cursor is at key: ", cursor.key);
          console.log("[DB Logging] Cursor is at value: ", cursor.value);
          try {
            for (const field in cursor.value) {
              // console.log(cursor.value[field]);
              cursor.value[field];
            }
          } catch (e) {
            throw Error(e);
          }

          return cursor.continue().then(logItems);
        })
        .then(() => {
          console.log("[DB Logging] Done!");
        })
        .catch(error => {
          console.error("[DB Logging] Error on db test", error);
          console.error(error);
          if (currentKey) {
            skippedLogObjects.push(currentKey);
          }

          this.errorLogCounter++;

          if (this.errorLogCounter < 50) {
            this.performLogDatabase(entity);
          }
        });
    },
    */
    async exportDatabase() {
      console.log("Export database called…");
      this.getCurrentDataUsage();

      this.exportError = null;
      this.exportProgress = 0;
      this.isExportRunning = true;

      try {
        // Generate database archive using import-export
        this.exportProgress = 10;
        this.exportLabel = "Exportiere Datenbank…";

        const db = await new Dexie("localforage").open();
        const blob = await exportDB(db, { progressCallback });
        blob.name = await getFileNameForDbExport();
        this.exportLabel = "Sende Datenbank, bitte warten…";
        this.exportProgress = 50;
        // Upload database export to GCS
        await uploadFilePart(blob);
        this.exportLabel = "Erfolgreich gesendet";
        this.exportProgress = 100;
        this.isExportRunning = false;
      } catch (e) {
        this.exportLabel = null;
        this.isExportRunning = false;
        this.exportProgress = 0;
        this.exportError = e;
        console.error("Error file uploading database", e);
      }
    },
    async importDatabase(file) {
      console.log("Import database called...");
      const db = await Dexie.import(file);
      return db.backendDB();
    },
    onDragOver(event) {
      const localEvent = event;
      localEvent.stopPropagation();
      localEvent.preventDefault();
      localEvent.dataTransfer.dropEffect = "copy";
    },
    async onDrop(event) {
      event.stopPropagation();
      event.preventDefault();

      // Pick the File from the drop event (a File is also a Blob):
      const file = event.dataTransfer.files[0];
      try {
        if (!file) throw new Error(`Only files can be dropped here`);
        console.log(`Loading ${file.name}`);
        console.log(file);

        const importMeta = await peakImportFile(file);
        console.log("Database name:", importMeta.data.databaseName);
        console.log("Database version:", importMeta.data.databaseVersion);
        console.log(
          "Tables:",
          importMeta.data.tables
            .map(t => `${t.name} (${t.rowCount} rows)`)
            .join("\n\t")
        );

        // let db = await new Dexie("localforage").open();
        // await db.delete();
        // console.log("Delete complete");

        const tempDb = await importDB(file, {
          progressCallback,
          acceptChangedPrimaryKey: true,
          overwriteValues: true,
        });
        tempDb.close();
        console.log("Loading complete");

        // db = await new Dexie("localforage").open();

        // // const db = await this.importDatabase(file);
        // console.log("Import of database complete", db);
        // await showContent();
      } catch (error) {
        console.error(`${error}`);
      }
    },
    closeView() {
      this.$router.push({
        path: "/settings",
      });
    },
  },
};
</script>

<style scoped>
nav span {
  padding-top: 6px;
}

.ui.grid > .column:not(.row) {
  padding-top: 10px !important;
}

.ui.progress:last-child {
  margin-bottom: 0;
}

.card {
  width: 100% !important;
}

#dropzone {
  height: 20px;
  border: 2px dotted #bbb;
  border-radius: 10px;
  padding: 40px;
  color: #bbb;
  text-align: center;
  border-radius: 8px;
}
</style>
