<template>
  <div>
    <!-- Navigation -->
    <NavBarJob title="Problem melden" :job="job"></NavBarJob>

    <main class="ui container">
      <form class="ui form" @submit.prevent="saveIssue" v-if="job">
        <!-- File Input -->
        <FileCaptureImage
          :job="job"
          :issue="issue"
          entity="issue"
          required="true"
          v-on:captured-image="handleDraftImageSelection($event)"
          v-on:deleted-image="handleDraftImageDeletion($event)"
        ></FileCaptureImage>
        <!-- Description -->
        <div class="ui field required">
          <label>Beschreibung</label>
          <p>Beschreibe das Problem in wenigen Worten.</p>
          <textarea
            class="multi"
            type="text"
            name="description"
            placeholder="Bemerkung hinzufügen…"
            spellcheck="true"
            autocorrect="on"
            rows="3"
            required
            :value="issue.description"
            @blur="setDescription($event)"
          ></textarea>
        </div>
        <!-- Work Blocker -->
        <div class="ui grouped fields required">
          <label>Fortsetzung der Aufgabe möglich?</label>
          <p>Wähle die zutreffende Option aus.</p>
          <div class="field">
            <div class="ui radio checkbox">
              <input
                type="radio"
                name="severity"
                v-model="severity"
                value="10"
                required
              />
              <label>Ja, Fortsetzung der Aufgabe ist möglich.</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input
                type="radio"
                name="severity"
                v-model="severity"
                value="20"
                required
              />
              <label>Nein, das Problem blockiert die Ausführung.</label>
            </div>
          </div>
        </div>
        <!-- Save Button -->
        <button
          class="ui fluid left labeled icon huge green button"
          type="submit"
          style="margin-top: 1.5em"
          :class="{ loading }"
        >
          <i class="save icon"></i>
          Problem speichern
        </button>
      </form>
    </main>

    <!-- Modal Cancel -->
    <ModalCancel
      :isConfirmLeavingViewModalShowing.sync="isConfirmLeavingViewModalShowing"
      :isConfirmLeavingViewModalConfirmed.sync="
        isConfirmLeavingViewModalConfirmed
      "
    ></ModalCancel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavBarJob from "@/components/NavBarJob.vue";
import ModalCancel from "@/components/modal/ModalCancel.vue";
import FileCaptureImage from "@/components/form/FileCaptureImage.vue";
import { EventBus } from "@/event-bus";

export default {
  name: "Issue",
  components: {
    NavBarJob,
    FileCaptureImage,
    ModalCancel,
  },
  data() {
    return {
      loading: false,
      title: null,
      issueDescription: null,
      issueContinueSelection: null,
      cancelRoute: null,
      isConfirmLeavingViewModalShowing: false,
      isConfirmLeavingViewModalConfirmed: false,
      draftImages: [],
    };
  },
  computed: {
    ...mapState({
      job: state => state.job.current,
      issue: state => state.issue.current,
      user: state => state.user.current,
    }),
    severity: {
      get() {
        if (!this.issue) {
          return null;
        }
        return this.issue.severity;
      },
      set(newValue) {
        this.$store.dispatch("issue/updateCurrent", {
          payload: { severity: newValue },
        });
      },
    },
  },
  watch: {
    // Fetch data if the route changes
    $route: "setCurrentIssue",
  },
  beforeRouteLeave(to, from, next) {
    // Show confirmation alert when user taps on the back button
    if (this.isConfirmLeavingViewModalConfirmed) {
      next();
    } else {
      this.showLeaveViewAlert(to);
      next(false);
    }
  },
  created() {
    // Get data
    this.setCurrentIssue();
  },
  mounted() {
    $(".ui.radio.checkbox").checkbox();

    // Handle cancel confirmation for leave modal
    EventBus.$once("cancelModalConfirmed", () => {
      this.isConfirmLeavingViewModalConfirmed = true;
      this.deleteDraftImages();

      this.$router.push({
        name:
          this.cancelRoute && this.cancelRoute.name
            ? this.cancelRoute.name
            : null,
        params:
          this.cancelRoute && this.cancelRoute.params
            ? this.cancelRoute.params
            : null,
      });
    });
  },
  methods: {
    async setCurrentIssue() {
      await this.$store
        .dispatch("job/setCurrent", { id: this.$route.params.jobId })
        .then(() =>
          this.$store.dispatch("issue/setCurrent", {
            issue: this.issue,
          })
        );
    },
    setDescription(event) {
      this.$store.dispatch("issue/updateCurrent", {
        payload: { description: event.target.value },
      });
    },
    setSeverity(event) {
      this.$store.dispatch("issue/updateCurrent", {
        payload: { severity: event.target.value },
      });
    },
    handleDraftImageSelection: function (event) {
      if (event && !this.draftImages.includes(event)) {
        this.draftImages.push(event);
      }
    },
    handleDraftImageDeletion: function (event) {
      if (event && this.draftImages.includes(event)) {
        this.draftImages.pop(event);
      }
    },
    deleteDraftImages() {
      this.draftImages.map(image => {
        this.$store.dispatch("image/delete", {
          fileName: image,
        });
      });
    },
    saveIssue() {
      this.isConfirmLeavingViewModalConfirmed = true;
      this.loading = true;

      this.$store
        .dispatch("issue/save", this.issue)
        .then(() =>
          this.$store.dispatch("job/update", {
            id: this.$route.params.jobId,
            payload: { sync_status: 0 },
          })
        )
        .then(() => {
          this.loading = false;
          EventBus.$emit("saved", "Problem gespeichert");
          this.$store.dispatch("sync/start");
          this.$router.push({
            name: "job",
            params: { jobId: this.$route.params.jobId },
          });
        });
    },
    showLeaveViewAlert(to) {
      this.isConfirmLeavingViewModalShowing = true;
      this.isConfirmLeavingViewModalConfirmed = false;
      this.cancelRoute = to;
    },
  },
};
</script>

<style>
.ui.form textarea {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}
</style>
