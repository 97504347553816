<template>
  <div>
    <nav class="ui top fixed menu">
      <span>Survello</span>
    </nav>
    <main class="ui">
      <div class="ui container">
        <h3 class="header">
          Mobile Zeiterfassung für mehr Transparenz und Sicherheit
        </h3>
        <img
          class="ui image rounded"
          src="./../assets/header.jpg"
          alt="Survello Header"
        />
        <router-link to="/login">
          <button
            class="fluid ui right labeled icon primary button large btn-login"
          >
            <i class="right arrow icon"></i>Anmelden
          </button>
        </router-link>
      </div>
    </main>
    <footer class="ui container">
      <p>
        &copy; 2020 Powered by
        <a href="https://www.survello.de" target="_blank" rel="noopener"
          >Survello</a
        >
        <span v-if="currentVersion">&nbsp;· Version {{ currentVersion }}</span>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      currentVersion: null,
    };
  },
  created() {
    this.currentVersion = process.env.VUE_APP_RELEASE;
  },
};
</script>

<style scoped>
.btn-login {
  margin-top: 16px !important;
}

footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 30px;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 11px;
}
</style>
