import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import Toasted from "vue-toasted";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/store";

const moment = require("moment");
require("moment/locale/de");

Vue.config.productionTip = false;

// Register global toasted instancen
Vue.use(Toasted);
Vue.use(AsyncComputed);
Vue.use(require("vue-moment"), {
  moment,
});

// Init Sentry crash reporter
if (process.env.NODE_ENV === "production") {
  LogRocket.init("wxhjza/bauzeit-app", {
    release: process.env.VUE_APP_RELEASE,
  });

  Sentry.init({
    dsn: "https://30cb4dde87854207886b28ae16cde2ce@sentry.io/1314037",
    environment:
      process.env.NODE_ENV !== "production" ? "development" : "production",
    release: process.env.VUE_APP_VERSION,
  });

  Sentry.configureScope(scope => {
    scope.addEventProcessor(async event => {
      event.extra.sessionURL = LogRocket.sessionURL;
      return event;
    });
  });
}

export default new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
