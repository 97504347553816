<template>
  <button
    v-if="isVisible"
    class="ui fluid left labeled icon big button"
    @click="pause"
  >
    <i class="left pause icon"></i>
    Arbeit pausieren
  </button>
</template>

<script>
export default {
  name: "ButtonJobPause",
  props: ["jobId", "userId", "isVisible"],
  methods: {
    pause() {
      this.$store
        .dispatch("timelog/pause", {
          jobId: this.jobId,
          userId: this.userId,
          eventType: "stop",
        })
        .catch(error => {
          console.error("Error pausing job", error);
        });
    },
  },
};
</script>
