<template>
  <div>
    <nav class="ui top fixed menu three column grid wide">
      <router-link to="/jobs">
        <div class="column" style="line-height: 24px; padding-top: 14px">
          <i class="arrow left large icon"></i>
        </div>
      </router-link>
      <div class="column menu__title left floated">
        <span>Aufgabe</span>
        <span v-if="this.fullName" class="subtitle">{{ this.fullName }}</span>
      </div>
    </nav>
    <main v-if="job">
      <!-- Job Meta Information -->
      <div class="ui card">
        <div class="content">
          <div class="header">{{ job.title }}</div>
          <div class="description">
            <span>
              Gebäude: {{ job.building }}
              <br />
              Etage: {{ job.floor }}
              <br />
              Raum: {{ job.room }}
            </span>
          </div>
        </div>
        <div v-if="job.info" class="content" v-html="job.info">
          {{ job.info }}
        </div>
      </div>
      <!-- Timer -->
      <TimerStatus
        :job="job"
        :user="user"
        :startTimelog="startTimelog"
        :stopTimelog="stopTimelog"
        style="padding-bottom: 1em"
      />
      <!-- Actions Buttons -->
      <div class="ui container" v-if="job && job.edit_status === 0">
        <ButtonJobStart
          :job="job"
          :startTimelog="startTimelog"
          :stopTimelog="stopTimelog"
          :user="user"
        />
        <ButtonJobPause
          :jobId="job.id"
          :userId="user.id"
          :isVisible="startTimelog && !stopTimelog && !job.paused_at"
        />
        <ButtonJobContinue
          :jobId="job.id"
          :userId="user.id"
          :isVisible="job.paused_at"
        />
        <ButtonJobStop
          :jobId="job.id"
          :userId="user.id"
          :isVisible="
            (startTimelog && !stopTimelog && !job.paused_at) || job.paused_at
          "
        />
        <button
          class="ui fluid left labeled icon big yellow button"
          @click="openIssue"
          style="margin-top: 2em"
        >
          <i class="left exclamation icon"></i>
          Problem melden
        </button>
        <!-- <button
          class="ui fluid left labeled icon big button"
          @click="openRemark"
        >
          <i class="left comment icon"></i>
          Anmerkung senden
        </button> -->
        <button
          class="ui fluid left labeled icon big button"
          :class="{ disabled: !stopTimelog }"
          @click="openApproval"
        >
          <i class="left check icon"></i>
          Interne Fertigmeldung
        </button>
      </div>
    </main>
    <!-- Confirm Job Modal -->
    <ModalJobComplete
      v-if="job"
      :jobId="job.id"
      :isJobCompleteModalShowing.sync="isJobCompleteModalShowing"
      :isJobCompleteModalConfirmed.sync="isJobCompleteModalConfirmed"
    ></ModalJobComplete>
    <!-- Mark existing timelogs as completed -->
    <ModalJobCompleteTimelogs
      v-if="job"
      :jobId="job.id"
      :isJobCompleteTimelogsModalShowing.sync="
        isJobCompleteTimelogsModalShowing
      "
      :isJobCompleteTimelogsConfirmed.sync="isJobCompleteTimelogsConfirmed"
    ></ModalJobCompleteTimelogs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import ModalJobComplete from "@/components/modal/ModalJobComplete.vue";
import ButtonJobStart from "@/components/buttons/ButtonJobStart.vue";
import ButtonJobPause from "@/components/buttons/ButtonJobPause.vue";
import ButtonJobContinue from "@/components/buttons/ButtonJobContinue.vue";
import ButtonJobStop from "@/components/buttons/ButtonJobStop.vue";
import ModalJobCompleteTimelogs from "@/components/modal/ModalJobCompleteTimelogs.vue";
import TimerStatus from "@/components/TimerStatus.vue";

export default {
  name: "Job",
  components: {
    ButtonJobStart,
    ButtonJobPause,
    ButtonJobContinue,
    ButtonJobStop,
    ModalJobComplete,
    ModalJobCompleteTimelogs,
    TimerStatus,
  },
  data() {
    return {
      isJobCompleteModalShowing: false,
      isJobCompleteModalConfirmed: true,
      isJobCompleteTimelogsModalShowing: false,
      isJobCompleteTimelogsConfirmed: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/current",
      fullName: "user/fullName",
    }),
    job() {
      return this.$store.getters["job/getById"](
        parseInt(this.$route.params.jobId)
      );
    },
    startTimelog() {
      return this.$store.getters["timelog/startByJobId"](
        parseInt(this.$route.params.jobId),
        this.user.id
      );
    },
    stopTimelog() {
      if (this.startTimelog && this.startTimelog.id) {
        return this.$store.getters["timelog/stopByJobId"](
          parseInt(this.$route.params.jobId),
          this.user.id,
          this.startTimelog.id
        );
      }
      return null;
    },
  },
  created() {
    // Handle confirm job modal
    EventBus.$on("SHOW_JOBCOMPLETE_MODAL", () => {
      this.isJobCompleteModalShowing = true;
      this.isJobCompleteModalConfirmed = false;
    });

    EventBus.$on("SHOW_JOBCOMPLETE_MODAL_CONFIRMED", jobId => {
      if (jobId) {
        this.$store
          .dispatch("job/update", {
            id: this.$route.params.jobId,
            payload: {
              edit_status: 1,
              sync_status: 0,
              finished_at: new Date().toISOString(),
            },
          })
          .then(() => {
            EventBus.$emit("saved");
            this.$store.dispatch("sync/start");
          });
      }
    });

    // Handle mark existing timelogs as finished modal
    EventBus.$on("SHOW_JOBCOMPLETETIMELOGS_MODAL", () => {
      this.isJobCompleteTimelogsModalShowing = true;
      this.isJobCompleteTimelogsConfirmed = false;
    });

    EventBus.$on("SHOW_JOBCOMPLETETIMELOGS_MODAL_CONFIRMED", jobId => {
      if (jobId) {
        this.$store
          .dispatch("timelog/completeExistingTimelogsForJob", {
            jobId: this.$route.params.jobId,
            userId: this.user.id,
          })
          .then(() => this.getData());
      }
    });
  },
  methods: {
    createNewTimelog() {
      EventBus.$emit("SHOW_JOBCOMPLETETIMELOGS_MODAL", this.job.id);
    },
    openStopTimelog() {
      if (!this.stopTimelog) {
        this.$store.dispatch("timelog/create", {
          jobId: parseInt(this.$route.params.jobId),
          userId: this.user.id,
        });
      }
      this.$router.push({
        name: "timelogStop",
        params: {
          jobId: parseInt(this.$route.params.jobId),
          timelogId: this.startTimelog ? this.startTimelog.id : null,
        },
      });
    },
    async openApproval() {
      const approval = await this.$store.dispatch("approval/getLatestForJob", {
        jobId: this.$route.params.jobId,
        userId: this.user.id,
      });
      this.$router.push({
        name: "jobApproval",
        params: { jobId: this.$route.params.jobId, approvalId: approval.id },
      });
    },
    async openIssue() {
      this.$store
        .dispatch("issue/create", {
          jobId: this.$route.params.jobId,
          userId: this.user.id,
        })
        .then(issue => this.$store.dispatch("issue/setCurrent", { issue }))
        .then(issue => {
          this.$router.push({
            name: "jobIssue",
            params: { jobId: this.$route.params.jobId, issueId: issue.id },
          });
        });
    },
    async openRemark() {
      this.$store
        .dispatch("remark/create", {
          jobId: this.$route.params.jobId,
          userId: this.user.id,
        })
        .then(remark => this.$store.dispatch("remark/setCurrent", { remark }))
        .then(remark => {
          this.$router.push({
            name: "jobRemark",
            params: { jobId: this.$route.params.jobId, remarkId: remark.id },
          });
        });
    },
    finishJob() {
      EventBus.$emit("SHOW_JOBCOMPLETE_MODAL", this.job.id);
    },
  },
};
</script>

<style scoped>
nav .menu__title {
  padding-top: 0px !important;
}
nav span {
  padding-top: 8px;
}
.ui.grid > * {
  padding-right: 0px !important;
}

.card {
  margin-bottom: 16px;
  width: 100%;
}

.button,
.ui.message {
  margin-bottom: 1em;
}

.ui.message {
  background-color: transparent;
}

h1 {
  margin-bottom: 5px !important;
  font-weight: 500 !important;
}

@media only screen and (min-width: 768px) {
  main {
    margin-top: 0px !important;
  }
  .card {
    margin: 0 auto;
    margin-bottom: 1em;
    max-width: 650px !important;
  }
}

@media only screen and (max-width: 767px) {
  main {
    margin-top: -15px !important;
  }
  .card {
    box-shadow: 0 1px 1px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
    border-radius: 0 !important;
  }
}
</style>
