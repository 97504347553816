<template>
  <a
    class="ui fluid card"
    :class="{ raised: isActive, green: isActive }"
    style="margin-bottom: 16px"
  >
    <div class="content" v-if="project" @click="selectProject">
      <div>
        <div
          v-if="isActive"
          class="status ui top right attached label tiny green"
        >
          AKTIV
        </div>
      </div>
      <div class="header">{{ project.title }}</div>
      <div class="description" v-html="project.description"></div>
    </div>
  </a>
</template>

<script>
export default {
  name: "CardProject",
  props: ["project", "currentProject", "user"],
  computed: {
    isActive() {
      return this.currentProject && this.currentProject.id === this.project.id;
    },
  },
  methods: {
    selectProject() {
      this.$store
        .dispatch("project/setCurrent", { id: this.project.id })
        .then(() => {
          this.$router.push({
            name: "jobs",
            params: {
              projectId: this.project.id,
            },
          });
        });
    },
  },
};
</script>
