import { apiClient } from "./ApiClient";

export default {
  fetchAll(credentialsParams) {
    return apiClient.sharedInstance().get("issues", null, {
      params: credentialsParams,
    });
  },

  upload(params) {
    // Limit number of images to 1
    let image;
    if (params.images && params.images.length > 0) {
      image = params.images[0].fileName;
    }

    const issue = {
      id: params.id,
      description: params.description,
      severity: params.severity,
      image: image,
      editStatus: params.edit_status,
      syncStatus: params.sync_status,
      staffId: params.user_id,
      jobId: params.job_id,
    };
    return apiClient.sharedInstance().patch("issues", issue);
  },
};
