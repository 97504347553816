<template>
  <button
    class="ui fluid left labeled icon big button"
    :class="{
      hidden:
        job.paused_at ||
        (!(
          startTimelog &&
          stopTimelog &&
          startTimelog.start_id === stopTimelog.start_id
        ) &&
          startTimelog),
    }"
    @click="start"
  >
    <i class="left play icon"></i>
    Arbeit starten
  </button>
</template>

<script>
export default {
  name: "button-job-start",
  props: ["job", "user", "startTimelog", "stopTimelog"],
  methods: {
    start() {
      const isFullCycle =
        this.startTimelog &&
        this.stopTimelog &&
        this.startTimelog.start_id === this.stopTimelog.start_id;
      this.$router.push({
        name: "timelogStart",
        params: {
          jobId: this.job.id,
          timelogId:
            !isFullCycle && this.startTimelog ? this.startTimelog.id : null,
        },
      });
    },
  },
};
</script>
