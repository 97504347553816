<template>
  <div>
    <nav class="ui top fixed menu">
      <span>Einstellungen</span>
    </nav>
    <main class="ui container">
      <div class="ui cards">
        <div class="card" style="width: 100%">
          <div class="content">
            <div class="header">
              <h4>Benutzerkonto</h4>
            </div>
            <div v-if="user" class="description">
              Der Benutzer
              <strong>{{ user.nickname }}</strong> ist aktuell angemeldet.
            </div>
            <div v-if="error" class="ui red message">
              <div class="header">Fehler aufgetreten</div>
              <p>Beim letzten Vorgang ist ein Fehler aufgetreten.</p>
              <p>Fehlermeldung: {{ error }}</p>
            </div>
          </div>
          <div class="extra content">
            <div
              class="ui button fluid btn-logout"
              @click="isUserLogoutModalShowing = true"
              v-bind:class="{ red: jobs.length > 0 }"
            >
              <i class="power icon"></i>
              Benutzer abmelden
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="header">
              <h4>Version</h4>
            </div>
            <div class="description">
              Aktuell verwendete Version der App: {{ currentVersion }}.
            </div>
          </div>
          <div class="extra content" v-bind:class="{ disabled: online }">
            <div
              v-if="remoteVersion && currentVersion !== remoteVersion"
              class="ui button fluid btn-update primary"
              @click="clickedCheckForUpdates"
            >
              <i class="refresh icon"></i>
              Auf Version {{ remoteVersion }} aktualisieren
            </div>
            <div
              v-else
              class="ui button fluid btn-update"
              @click="clickedCheckForUpdates"
            >
              <i class="refresh icon"></i>Nach Aktualisierung suchen
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="header">
              <h4>Support</h4>
            </div>
            <div class="description">
              Falls bei der Nutzung Probleme auftreten sollten, kann zwischen
              diesen beiden Funktionen gewählt werden.
            </div>
          </div>
          <div class="extra content">
            <div class="ui button fluid" @click="clickedDiagnosis">
              <i class="terminal icon"></i>Support öffnen
            </div>
          </div>
        </div>
      </div>
    </main>
    <TabBar></TabBar>

    <modal
      black
      v-model="isUserLogoutModalShowing"
      modalVariation="basic"
      style="padding-top: 15rem"
    >
      <div slot="header">
        <div
          v-if="
            jobs.length > 0 ||
            timelogs.length > 0 ||
            issues.length > 0 ||
            approvals.length > 0
          "
        >
          <i class="exclamation triangle icon"></i>
          Datenverlust möglich
        </div>
        <div v-else>
          <i class="power icon"></i>
          Benutzer abmelden
        </div>
      </div>
      <div class="content" slot="content">
        <div
          v-if="
            jobs.length > 0 ||
            timelogs.length > 0 ||
            issues.length > 0 ||
            approvals.length > 0
          "
        >
          <p>
            Durch die Abmeldung werden noch nicht übertragener Daten gelöscht.
            Dieser können später
            <u>nicht</u> wiederhergestellt werden.
          </p>
          <p>
            <strong>Möchten Sie sich wirklich abmelden?</strong>
          </p>
        </div>
        <div v-else class="ui red">
          <p>
            <strong>Möchten Sie sich wirklich abmelden?</strong>
          </p>
          <p>
            Für eine erneute Anmeldung ist eine Internetverbindung erforderlich.
          </p>
        </div>
        <br />
      </div>
      <template slot="actions">
        <div
          class="ui black deny inverted button"
          @click="isUserLogoutModalShowing = false"
        >
          <i class="remove icon"></i> Nein
        </div>
        <div
          class="ui red right inverted button"
          @click="confirmUserLogoutModal"
        >
          <i class="checkmark icon"></i> Ja
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modal from "vue-semantic-modal";
import TabBar from "@/components/TabBar";
import getVersionFromManifest from "@/utils/helper";

export default {
  name: "Settings",
  components: {
    TabBar,
    modal,
  },
  data() {
    return {
      loading: false,
      error: null,
      online: null,
      currentVersion: null,
      remoteVersion: null,
      isUserLogoutModalShowing: false,
      isUserLogoutConfirmed: true,
    };
  },
  computed: {
    ...mapGetters({
      jobs: "job/pending",
      issues: "issue/pending",
      images: "image/pending",
      timelogs: "timelog/pending",
      approvals: "approval/pending",
      user: "user/current",
    }),
  },
  async mounted() {
    this.online = window.navigator.onLine;
    this.currentVersion = process.env.VUE_APP_RELEASE;
    this.remoteVersion = await getVersionFromManifest();
  },
  methods: {
    showUserLogoutModal() {
      this.isUserLogoutModalShowing = true;
      this.isUserLogoutConfirmed = false;
    },
    confirmUserLogoutModal() {
      this.isUserLogoutModalShowing = false;
      this.isUserLogoutConfirmed = true;

      this.$store.dispatch("user/logout").then(() => {
        this.$router.push({
          path: "/",
        });
      });
    },
    clickedCheckForUpdates() {
      const updateBtn = document.querySelector(".btn-update");
      const classListBackup = updateBtn.classList;
      updateBtn.classList += " loading";

      setTimeout(() => {
        this.loading = true;
        window.location.reload(true);
        updateBtn.classList = classListBackup;
        this.loading = false;
      }, 300);
    },
    clickedDiagnosis() {
      this.$router.push({
        path: "/settings/diagnosis",
      });
    },
  },
};
</script>

<style scoped>
.card {
  width: 100% !important;
}
.cards {
  padding-bottom: 50px;
}
</style>
