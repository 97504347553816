<template>
  <div>
    <nav class="ui top fixed menu">
      <span>Warteschlange</span>
    </nav>
    <main class="ui">
      <!-- Sync Button -->
      <div
        class="ui container"
        v-if="!isSyncing && jobs && jobs.length > 0"
        style="padding-bottom: 0"
      >
        <button
          class="fluid ui left labeled icon blue big button"
          v-bind:class="{ disabled: isSyncing, loading: isSyncing }"
          style="padding-left: 3.5em !important; margin-bottom: 1em"
          @click="startSync"
        >
          <i class="left cloud upload icon"></i>
          Warteschlange übertragen
        </button>
      </div>
      <!-- // Sync Button -->
      <!-- Sync Process Message -->
      <div
        v-if="!loading && isSyncing"
        class="ui container"
        style="padding-bottom: 1em"
      >
        <div class="ui icon small message blue">
          <i class="notched circle loading icon"></i>
          <div class="content">
            <div class="header">Übertragung läuft …</div>
            <p>Die Warteschlange wird übertragen.</p>
          </div>
        </div>
      </div>
      <!-- // Sync Process Message  -->
      <!-- Empty Queue Message -->
      <div
        v-if="(!jobs || jobs.length < 1) && !isSyncing"
        class="ui container center aligned"
        style="padding-top: 3em"
      >
        <img src="./../assets/icon-sync-empty.png" width="115px" />
        <h3 class="deactive">Warteschlange ist leer</h3>
        <p class="deactive">
          Zu übertragende Aufgaben und gemeldete Probleme erscheinen in dieser
          Liste.
        </p>
      </div>
      <!-- // Empty Queue Message  -->
      <!-- List Jobs -->
      <div
        v-if="jobs && jobs.length > 0"
        class="ui container"
        style="padding-bottom: 50px"
      >
        <template v-for="job in jobs">
          <!--
            Jobs
            > Job
              > Timelogs
                > Timelog
                  > Images
                    > Image
              > Issues
                > Issue
                  > Images
                    > Image
              > Approvals
                > Approval
                  > Images
                    > Image
          -->
          <div v-bind="job" v-bind:key="job.id" class="ui fluid card">
            <div class="content">
              <div>
                <div
                  v-if="job.edit_status === 1"
                  class="ui top right attached label tiny green"
                >
                  FERTIG GEMELDET
                </div>
              </div>
              <div class="header">{{ job.title }}</div>
              <div class="meta" style="padding-bottom: 8px">
                <span v-if="job.sent_at" class="floated time"
                  >Abgeschlossen {{ job.finished_at | moment("LLL") }}</span
                >
                <span v-else class="floated time"
                  >Bearbeitet {{ job.updated_at | moment("LLL") }} Uhr</span
                >
              </div>
              <div class="extra content">
                <div class="ui list">
                  <div class="item">
                    <div class="content">
                      <p class="header deactive">Aufgabe</p>
                      <div
                        v-if="job.sync_status < 1"
                        class="description deactive"
                      >
                        0 % übertragen …
                      </div>
                      <div
                        v-else-if="job.sync_status === 1"
                        class="description deactive"
                      >
                        wird übertragen …
                      </div>
                      <div
                        v-else-if="job.sync_status === 2"
                        class="description deactive"
                      >
                        100 % übertragen
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Timelogs List -->
                <div
                  class="ui list"
                  v-for="timelog in timelogs"
                  :bind="timelog"
                  :key="timelog.id"
                >
                  <div class="item" v-if="timelog.job_id === job.id">
                    <div class="content">
                      <p class="header deactive">Zeiteintrag</p>
                      <div
                        v-if="timelog.sync_status < 1"
                        class="description deactive"
                      >
                        0 % übertragen …
                      </div>
                      <div
                        v-else-if="timelog.sync_status === 1"
                        class="description deactive"
                      >
                        wird übertragen …
                      </div>
                      <div
                        v-else-if="timelog.sync_status === 2"
                        class="description deactive"
                      >
                        100 % übertragen
                      </div>
                    </div>
                  </div>
                  <div
                    class="item"
                    v-for="image in images"
                    :bind="image"
                    :key="image.id"
                  >
                    <div
                      class="content"
                      v-if="image.referenceId === timelog.id"
                    >
                      <p class="header deactive">Foto (Zeiteintrag)</p>
                      <div class="description deactive">
                        {{ getUploadProgressForFile(image) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Timelogs List -->
                <!-- Issues List -->
                <div
                  class="ui list"
                  v-for="issue in issues"
                  :bind="issue"
                  :key="issue.id"
                >
                  <div class="item" v-if="issue.job_id === job.id">
                    <div class="content">
                      <p class="header deactive">Problem</p>
                      <div class="description deactive">
                        <div
                          v-if="issue.sync_status < 1"
                          class="description deactive"
                        >
                          0 % übertragen …
                        </div>
                        <div
                          v-else-if="issue.sync_status === 1"
                          class="description deactive"
                        >
                          wird übertragen …
                        </div>
                        <div
                          v-else-if="issue.sync_status === 2"
                          class="description deactive"
                        >
                          100 % übertragen
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="item"
                    v-for="image in images"
                    :bind="image"
                    :key="image.id"
                  >
                    <div class="content" v-if="image.referenceId === issue.id">
                      <p class="header deactive">Foto (Problem)</p>
                      <div class="description deactive">
                        {{ getUploadProgressForFile(image) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Issues List -->
                <!-- Remarks List -->
                <div
                  class="ui list"
                  v-for="remark in remarks"
                  :bind="remark"
                  :key="remark.id"
                >
                  <div class="item" v-if="remark.job_id === job.id">
                    <div class="content">
                      <p class="header deactive">Anmerkung</p>
                      <div class="description deactive">
                        <div
                          v-if="remark.sync_status < 1"
                          class="description deactive"
                        >
                          0 % übertragen …
                        </div>
                        <div
                          v-else-if="remark.sync_status === 1"
                          class="description deactive"
                        >
                          wird übertragen …
                        </div>
                        <div
                          v-else-if="remark.sync_status === 2"
                          class="description deactive"
                        >
                          100 % übertragen
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="item"
                    v-for="image in images"
                    :bind="image"
                    :key="image.id"
                  >
                    <div class="content" v-if="image.referenceId === remark.id">
                      <p class="header deactive">Foto (Anmerkung)</p>
                      <div class="description deactive">
                        {{ getUploadProgressForFile(image) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Issues List -->
                <!-- Approvals List -->
                <div
                  class="ui list"
                  v-for="approval in approvals"
                  :bind="approval"
                  :key="approval.id"
                >
                  <div class="item" v-if="approval.job_id === job.id">
                    <div class="content">
                      <p class="header deactive">Interne Fertigmeldung</p>
                      <div class="description deactive">
                        <div
                          v-if="approval.sync_status < 1"
                          class="description deactive"
                        >
                          0 % übertragen …
                        </div>
                        <div
                          v-else-if="approval.sync_status === 1"
                          class="description deactive"
                        >
                          wird übertragen …
                        </div>
                        <div
                          v-else-if="approval.sync_status === 2"
                          class="description deactive"
                        >
                          100 % übertragen
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="item"
                    v-for="image in images"
                    :bind="image"
                    :key="image.id"
                  >
                    <div
                      class="content"
                      v-if="image.referenceId === approval.id"
                    >
                      <p class="header deactive">
                        Foto (Interne Fertigmeldung)
                      </p>
                      <div class="description deactive">
                        {{ getUploadProgressForFile(image) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- // Approvals List -->
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- // List Jobs -->
    </main>
    <TabBar></TabBar>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TabBar from "@/components/TabBar";
import { getUploadProcessForImage } from "@/utils/helper";

export default {
  name: "Sync",
  components: {
    TabBar,
  },
  data() {
    return {
      loading: false,
      online: false,
      error: null,
    };
  },
  mounted() {
    this.$store.dispatch("sync/setStatus", false);
  },
  computed: {
    ...mapGetters({
      jobs: "job/pending",
      issues: "issue/pending",
      remarks: "remark/pending",
      images: "image/pending",
      timelogs: "timelog/pending",
      approvals: "approval/pending",
    }),
    ...mapState({
      currentUser: state => state.user.current,
      isSyncing: state => state.sync.isActive,
    }),
  },
  methods: {
    getUploadProgressForFile(image) {
      return getUploadProcessForImage(image);
    },
    startSync() {
      this.$store.dispatch("sync/start");
    },
  },
};
</script>
