import store from "@/store/store";
import {
  ISSUE_ADD,
  ISSUE_UPDATE,
  ISSUE_UPDATE_CURRENT,
  ISSUE_SET_CURRENT,
  ISSUE_SET_CURRENT_ATTRIBUTE,
  ISSUE_SET_CURRENT_PICTURE,
  ISSUE_DELETE_CURRENT_PICTURE,
  ISSUE_SAVE,
  ISSUE_DELETE,
  ISSUE_RESET,
} from "@/store/mutation-types";
import IssueService from "@/services/IssueService.js";

export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  CLOSED: 2,
  SENT: 3,
});

function createNewIssue({ jobId, userId }) {
  return {
    id: new Date().getTime(),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    sync_status: 0,
    edit_status: 2,
    user_id: userId,
    job_id: jobId,
    description: null,
    severity: 0,
    images: null,
  };
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
  current: null,
};

/**
 * Getters
 */
export const getters = {
  current: state => state.current,
  all: state => state.list,
  pending: state =>
    state.list.filter(issue => issue && issue.sync_status !== 2),
};

/**
 * Actions
 */
export const actions = {
  /**
   * Creates a new issue and returns it
   * @param {*} param0
   * @param {*} param1
   */
  create(_, { jobId, userId }) {
    return new Promise(async resolve => {
      const newIssue = await createNewIssue({
        jobId,
        userId,
      });
      resolve(newIssue);
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  update({ commit }, { id, payload }) {
    return new Promise(resolve => {
      commit(ISSUE_UPDATE, {
        id,
        payload,
      });
      resolve();
    });
  },
  updateCurrent({ commit }, { payload }) {
    return new Promise(resolve => {
      commit(ISSUE_UPDATE_CURRENT, {
        payload,
      });
      resolve();
    });
  },
  /**
   * Saves the given issue
   * @param {*} param0
   * @param {*} timelog
   */
  save({ commit, state }, issue) {
    return new Promise(resolve => {
      // Add issue to list
      commit(ISSUE_ADD, issue);

      // Get index of added issue
      const index = state.list.findIndex(element => element.id === issue.id);
      if (index === -1)
        throw Error(`Issue with id ${issue.id} not found in store`);

      commit(ISSUE_SAVE, {
        issue,
        index,
      });
      resolve();
    });
  },
  /**
   * Sets the current issue
   * @param {*} param0
   * @param {*} issueId
   */
  setCurrent({ commit, state }, { issueId, issue }) {
    return new Promise(resolve => {
      let localIssue;
      if (!issue) {
        localIssue = state.list.find(
          element => element.id === issueId.toString()
        );
        if (!localIssue)
          throw Error(`Issue with id ${issueId} not found in store`);
      } else {
        localIssue = issue;
      }

      commit(ISSUE_SET_CURRENT, localIssue);
      resolve(localIssue);
    });
  },
  /**
   * Sets the given attribute to the given value
   * @param {*} param0
   * @param {*} param1
   */
  setCurrentAttribute({ commit }, { fieldName, value }) {
    return new Promise(resolve => {
      commit(ISSUE_SET_CURRENT_ATTRIBUTE, {
        fieldName,
        value,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setCurrentPicture({ commit, state }, { issueId, fileName }) {
    return new Promise(resolve => {
      // Find given timelog by id
      const issue = state.current;
      if (!issue) throw Error(`Issue with id ${issueId} not found in store`);

      commit(ISSUE_SET_CURRENT_PICTURE, {
        fileName,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  deleteCurrentPicture({ commit, state }, { issueId, fileName }) {
    return new Promise(resolve => {
      // Find given issue by id
      const issue = state.current;
      if (!issue) throw Error(`Timelog with id ${issueId} not found in store`);

      commit(ISSUE_DELETE_CURRENT_PICTURE, {
        fileName,
      });
      resolve();
    });
  },
  /**
   * Resets all available data
   */
  reset({ commit }) {
    return new Promise(resolve => {
      commit(ISSUE_RESET);
      resolve();
    });
  },
  /**
   * Upload pending issues
   */
  uploadPending() {
    return new Promise((resolve, reject) => {
      const items = store.getters["issue/pending"];
      console.log("[issue/uploadPending] called", items);

      // Check if pending items are available
      if (!items || items.length === 0) {
        console.log("[issue/uploadPending] No pending items available");
        resolve();
      } else {
        // Upload responses
        items
          .reduce(
            (sequence, item) =>
              sequence.then(() => {
                console.log("[issue/uploadPending] Uploading item…", item);
                return store.dispatch("issue/upload", item);
              }),
            Promise.resolve()
          )
          .then(() => {
            console.log("[issue/uploadPending] Uploaded with success");
            resolve();
          })
          .catch(error => {
            console.error("[issue/uploadPending] Error while uploading", error);
            reject(error);
          });
      }
    });
  },
  upload({ commit, dispatch }, issue) {
    return new Promise((resolve, reject) => {
      console.log("[issue/upload] called", issue);
      // Set sync state to pending
      dispatch("update", {
        id: issue.id,
        payload: {
          sync_status: 0,
        },
      })
        .then(() =>
          // Upload images for timelog
          store.dispatch("image/uploadByReferenceId", {
            referenceId: issue.id,
          })
        )
        .then(() =>
          // Upload timelog
          IssueService.upload(issue)
        )
        .then(response => {
          if (response.status != 200 && response.status != 201)
            throw new Error("Error uploading issue");
          return response.data.data;
        })
        .then(() => {
          // Save sync state
          commit(ISSUE_UPDATE, {
            id: issue.id,
            payload: {
              uploaded_at: new Date().toISOString(),
              sync_status: 2,
            },
          });
          resolve();
        })
        .then(() => resolve())
        .catch(error => {
          // Catch error and set issue to pending
          dispatch("update", {
            id: issue.id,
            payload: {
              sync_status: 0,
            },
          });
          reject(error);
        });
    });
  },
  cleanSynced({ dispatch }) {
    return new Promise(async resolve => {
      await dispatch("abstract/cleanSynced");
      resolve();
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} issue
   */
  [ISSUE_ADD](state, issue) {
    state.list.push(issue);
  },
  [ISSUE_UPDATE](state, { id, payload }) {
    const index = state.list.findIndex(element => element.id == id);
    if (index === -1) throw Error(`Issue with id ${id} not found in store`);

    let issue = state.list.find(element => element.id == id);
    issue = Object.assign(issue, payload);

    state.list[index] = issue;
  },
  [ISSUE_UPDATE_CURRENT](state, { payload }) {
    state.current = Object.assign(state.current, payload);
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [ISSUE_SAVE](state, { issue, index }) {
    state.list.splice(index, 1, issue);
  },
  [ISSUE_DELETE](state, { index }) {
    state.list.splice(index, 1);
  },
  /**
   *
   * @param {*} state
   * @param {*} issue
   */
  [ISSUE_SET_CURRENT](state, issue) {
    state.current = issue;
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [ISSUE_SET_CURRENT_ATTRIBUTE](state, { fieldName, value }) {
    state.current[fieldName] = value;
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [ISSUE_SET_CURRENT_PICTURE](state, { fileName }) {
    if (!state.current.images) {
      state.current.images = [];
    }
    state.current.images.push({
      fileName,
      url: null,
    });
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [ISSUE_DELETE_CURRENT_PICTURE](state, { fileName }) {
    const index = state.current.images.findIndex(
      element => element.fileName === fileName
    );
    if (index === -1)
      throw Error(`Image with fileName ${fileName} not found in issue`);

    state.current.images.splice(index);
  },
  /**
   * Resets state for issues entity
   * @param {*} state
   */
  [ISSUE_RESET](state) {
    state.current = null;
    state.list = [];
  },
};
