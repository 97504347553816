<template lang="html">
  <div>
    <NavBarProjects />
    <main class="ui container">
      <div
        v-if="!projects || Object.keys(projects).length === 0"
        class="ui container center aligned"
        style="padding-top: 3em"
      >
        <img src="./../assets/icon-inquiries-empty.png" width="75px" />
        <h3 class="deactive">Keine Projekte gefunden</h3>
        <p class="deactive">
          Sobald offene Aufgaben zur Verfügung stehen, werden diese hier
          angezeigt.
        </p>
      </div>
      <div v-else>
        <p style="color: gray; font-weight: 600">
          Bitte wähle ein Projekt aus, um die darin enthaltenen Aufgaben sehen
          zu können.
        </p>
        <div v-for="project in projects" :key="project.id">
          <CardProject
            :project="project"
            :currentProject="currentProject"
            :user="currentUser"
          ></CardProject>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NavBarProjects from "@/components/NavBarProjects.vue";
import CardProject from "@/components/CardProject.vue";

export default {
  name: "Projects",
  components: {
    NavBarProjects,
    CardProject,
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current,
      currentProject: state => state.project.current,
    }),
    ...mapGetters({
      projects: "project/all",
      fullName: "user/fullName",
    }),
  },
};
</script>
