/**
 * images/api
 */
import store from "@/store/store";
import { dataURItoBlob } from "@/utils/helper";

const api = {
  /**
   * Requests the upload ID for a given image
   * @param credentials
   */
  getUploadId({ image }) {
    return new Promise((resolve, reject) => {
      const url = `https://www.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_GCS_BUCKET}/o?uploadType=resumable&key=${process.env.VUE_APP_GCS_KEY}&name=${image.fileName}`;
      const header = {
        "Accept-Encoding": "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        "Content-Length": image.contentSize,
        "X-Upload-Content-Length": image.size,
        "X-Upload-Content-Type": "image/jpeg",
      };

      fetch(url, {
        method: "post",
        headers: header,
      })
        .then(response => {
          if (!response.ok) throw Error("Response not ok", response);
          if (!response.headers.get("Location"))
            throw Error("No location header present in response");

          return response;
        })
        .then(response => {
          // Split upload id from Google response
          const locationHeader = response.headers.get("Location");
          const responseSplit = locationHeader.split("&");
          const uploadId = responseSplit[3].substring(10);
          resolve(uploadId);
        })
        .catch(error => reject(error));
    });
  },
  /**
   *
   */
  postImageChunk({ imageId, isResuming = false, chunkSize = 262144 }) {
    return new Promise((resolve, reject) => {
      // Get current image from store
      const image = store.getters["image/pending"].find(
        item => item.id === imageId
      );
      if (!image) throw Error(`Image with id ${imageId} not found in store`);

      // Init the current upload content range if the image has not been uploaded before
      const uploadCurrentRange = image.uploadCurrentRange
        ? image.uploadCurrentRange
        : 0;

      // Set the lower and upper value of the range, e.g. 0/2612144 for the first chunk
      let localChunkSize = chunkSize;
      const currentContentRange = uploadCurrentRange;
      let totalContentRange = currentContentRange + localChunkSize;

      // Check if we're going to upload the last chunk of the current image.
      // If adding the default chunk size of 256 KB exceeds the total image size,
      // we detected the last part. Instead of the default 256 KB chunk size
      // we go ahead and calculcate the diff between the current range and the total image size.
      if (totalContentRange > image.size) {
        localChunkSize = image.size - currentContentRange;
        totalContentRange = image.size - 1;
      }

      // Set the content-range header value. If the previous upload failed, we use a
      // wildcard for the current chunk size. Google will then respond with the current
      // content range.
      let rangeHeader;
      if (isResuming) {
        rangeHeader = `bytes */${image.size}`;
      } else {
        rangeHeader = `bytes ${currentContentRange}-${totalContentRange}/${image.size}`;
      }

      // Get the blob for the content range by slicing the current image
      const source = dataURItoBlob(image.src);
      const blob = source.slice(
        uploadCurrentRange,
        totalContentRange + 1,
        "image/jpeg"
      );

      const request = {
        method: "put",
        headers: {
          "Accept-Encoding": "application/json",
          "Content-Type": "image/jpeg",
          "Content-Length": isResuming ? localChunkSize : 0,
          "Content-Range": rangeHeader,
        },
        body: isResuming ? null : blob,
      };

      const url = `https://www.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_GCS_BUCKET}/o?uploadType=resumable&key=${process.env.VUE_APP_GCS_KEY}&upload_id=${image.uploadId}`;

      fetch(url, request)
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
};

export default api;
