import { EventBus } from "@/event-bus";
import { SYNC_SET_STATUS } from "@/store/mutation-types";
import store from "@/store/store";

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  // Boolean if sync is currently active
  isActive: false,
};

/**
 * Getters
 */
export const getters = {
  isActive: state => state.isActive,
};

/**
 * Actions
 */

export const actions = {
  /**
   * Starts sync process
   */
  start() {
    console.log("Sync started called…");
    if (store.state.sync.isActive) {
      console.log("Sync already running, aborting…");
      return false;
    }

    return store
      .dispatch("sync/setStatus", true)
      .then(() => store.dispatch("remark/uploadPending"))
      .then(() => store.dispatch("issue/uploadPending"))
      .then(() => store.dispatch("timelog/uploadPending"))
      .then(() => store.dispatch("approval/uploadPending"))
      .then(() => store.dispatch("job/uploadPending"))
      .then(() =>
        store.dispatch("cleanSynced", {
          entity: "issue",
        })
      )
      .then(() => store.dispatch("job/cleanSynced"))
      .then(() => store.dispatch("sync/setStatus", false))
      .then(() => {
        EventBus.$emit("synced");
        console.log("Sync finished with success");
      })
      .catch(error => {
        console.error("Error syncing", error);
        store.dispatch("sync/setStatus", false);
      });
  },
  /**
   * Set the status flag to true
   * @param {*} param0
   * @param {*} status
   */
  setStatus({ commit }, status) {
    return new Promise(resolve => {
      commit(SYNC_SET_STATUS, status);
      resolve();
    });
  },
  download() {
    return store
      .dispatch("sync/setStatus", true)
      .then(() => {
        if (process.env.VUE_APP_JOBPOOL_ACTIVE) {
          return store.dispatch("project/fetchAll");
        }
        return true;
      })
      .then(() => store.dispatch("job/fetchAll"))
      .then(() => store.dispatch("sync/setStatus", false))
      .then(() => store.dispatch("sync/start"))
      .catch(() => store.dispatch("sync/setStatus", false));
  },
};

/**
 * Mutations
 */
export const mutations = {
  [SYNC_SET_STATUS](state, status) {
    state.isActive = status;
  },
};
