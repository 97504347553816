import loadImage from "blueimp-load-image";
import store from "@/store/store";
import api from "@/store/images/api";

import { formatBytes, formatPercentage } from "@/utils/helper";

import {
  IMAGES_ADD,
  IMAGES_SET_ATTRIBUTE,
  IMAGES_SET_IMAGE_URL_IN_ENTITY,
  IMAGES_DELETE,
  IMAGES_RESET,
} from "@/store/mutation-types";

export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  CLOSED: 2,
  SENT: 3,
});

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

/**
 * Namespaced
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
  current: null,
};

/**
 * Getters
 */
export const getters = {
  all: state => state.list,
  pending: state =>
    state.list.filter(
      image => image && image.syncStatus !== SYNC_STATUS.SYNCED
    ),
};

/**
 * Actions
 */
export const actions = {
  /**
   * Adds an image to the state
   * @param {*} state
   * @param {*} image
   */
  add({ commit }, { file, fileName, referenceId, referenceEntity }) {
    return new Promise(async resolve => {
      // Downscale image to 1.2MP
      let data = await loadImage(file, {
        maxWidth: 1200,
        maxHeight: 1200,
        orientation: true,
        crop: false,
        canvas: true,
      });

      // Reduce file size and get resized file size
      const dataBase64 = data.image.toDataURL("image/jpeg", 0.8);
      const dataContent = dataBase64.split(",")[1];
      const dataSize = window.atob(dataContent).length;

      // Generate a unique ID for the new inquiry
      const image = {
        id: new Date().getTime(),
        userId: store.getters["user/current"].id.toString(),
        referenceId,
        referenceEntity,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        uploadedAt: null,
        syncStatus: SYNC_STATUS.PENDING,
        fileName,
        src: dataBase64,
        size: dataSize,
        uploadId: null,
        uploadCurrentRange: null,
        url: null,
      };

      resolve(image);
    }).then(image => {
      commit(IMAGES_ADD, {
        image,
      });
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setAttribute({ commit }, { imageId, fieldName, value }) {
    return new Promise(resolve => {
      commit(IMAGES_SET_ATTRIBUTE, {
        imageId,
        fieldName,
        value,
      });
      resolve();
    });
  },
  /**
   * Returns upload process in percentage for given file
   * @param {*} _
   * @param {*} image
   */
  getUploadProcess(_, image) {
    return new Promise(resolve => {
      let localCurrent = image.uploadCurrentRange || 0;
      let localTotal = image.size || 0;

      let percentage = null;
      if (localCurrent === 0) {
        percentage = 0;
      } else if (image.syncStatus === 2) {
        percentage = 100;
      } else {
        percentage = formatPercentage(localCurrent, localTotal);
      }

      localTotal = formatBytes(localTotal);

      if (percentage && (percentage === 0 || percentage >= 100)) {
        const result = `${percentage} % (${localTotal}) übertragen`;
        resolve(result);
      }

      localCurrent = formatBytes(localCurrent);
      const result = `${percentage} % (${localCurrent} / ${localTotal}) übertragen`;
      resolve(result);
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  delete({ commit }, { fileName }) {
    return new Promise(resolve => {
      commit(IMAGES_DELETE, {
        fileName,
      });
      resolve();
    });
  },
  /**
   * Resets all available data
   */
  reset({ commit }) {
    return new Promise(resolve => {
      commit(IMAGES_RESET);
      resolve();
    });
  },
  /**
   *
   * @param {*} _
   * @param {*} referenceId
   */
  uploadByReferenceId({ dispatch, state }, { referenceId }) {
    return new Promise(async (resolve, reject) => {
      console.log("[image/uploadByReferenceId] Called…", referenceId);

      // Get all images for the given reference ID
      const images = await state.list.filter(
        image =>
          image.referenceId === referenceId &&
          image.syncStatus !== SYNC_STATUS.SYNCED
      );

      console.log("[image/uploadByReferenceId] Pending images", images);
      return images
        .reduce(
          (sequence, image) => sequence.then(() => dispatch("upload", image)),
          Promise.resolve()
        )
        .then(() => {
          console.log(
            "[image/uploadByReferenceId] Finished uploading with success"
          );
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  /**
   *
   * @param {*} _
   * @param {*} param1
   */
  upload({ dispatch }, image) {
    return new Promise((resolve, reject) => {
      console.log("[image/upload] Called…", image.id);
      // Update sync state to syncing
      dispatch("setAttribute", {
        imageId: image.id,
        fieldName: "syncStatus",
        value: 1,
      })
        .then(async () => {
          // Request upload ID if not present
          if (!image.uploadId) {
            console.log("[image/upload] Requesting upload id…", image.id);
            return dispatch("requestUploadId", {
              imageId: image.id,
            });
          }
          console.log("[image/upload] Upload id present", image.uploadId);
          return true;
        })
        .then(async () => {
          // Upload image parts
          console.log("[image/upload] Uploading image parts", image.id);
          return dispatch("uploadImagePart", {
            imageId: image.id,
          });
        })
        .then(async () => {
          console.log("[image/upload] Finished upload with success", image.id);
          // Update sync state to synced
          await dispatch("setAttribute", {
            imageId: image.id,
            fieldName: "syncStatus",
            value: 2,
          });
          resolve();
        })
        .catch(error => {
          // Reset sync state to pending on error
          console.error("[image/upload] Error uploading image", error);
          dispatch("setAttribute", {
            imageId: image.id,
            fieldName: "syncStatus",
            value: 0,
          });
          reject();
        });
    });
  },
  /**
   *
   * @param {*} _
   * @param {*} image
   */
  requestUploadId({ dispatch }, { imageId }) {
    return new Promise((resolve, reject) => {
      // Get current image from store
      const image = store.getters["image/all"].find(
        item => item.id === imageId
      );
      if (!image) throw Error(`Image with id ${imageId} not found in store`);

      api
        .getUploadId({
          image,
        })
        .then(uploadId => {
          // Save upload id in corresponding image image
          console.log("[image/requestUploadId] Saving upload id", uploadId);
          resolve(
            dispatch("setAttribute", {
              imageId: image.id,
              fieldName: "uploadId",
              value: uploadId,
            })
          );
        })
        .catch(error => {
          console.log(
            "[image/requestUploadId] Error fetching upload id",
            error
          );
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  uploadImagePart(
    { commit, dispatch },
    { imageId, isResuming = false, chunkSize = 262144 }
  ) {
    return new Promise((resolve, reject) => {
      // Get current image from store
      const image = store.getters["image/pending"].find(
        item => item.id === imageId
      );
      if (!image) throw Error(`Image with id ${imageId} not found in store`);

      api
        .postImageChunk({
          imageId: image.id,
          isResuming,
          chunkSize,
        })
        .then(async response => {
          if (response.status === 308) {
            console.log("[image/uploadImagePart] Code 308");

            // Save the received content-range to make sure that the upload
            // of the next chunk starts at the correct bytes
            const currentRangeHeader = response.headers.get("Range");
            if (currentRangeHeader) {
              const responseSplit = currentRangeHeader.split("-");
              commit(IMAGES_SET_ATTRIBUTE, {
                imageId: image.id,
                fieldName: "uploadCurrentRange",
                value: parseInt(responseSplit[1], 10),
              });
            }

            resolve(
              dispatch("uploadImagePart", {
                imageId: image.id,
                isResuming: false,
              })
            );
          } else if (response.status === 400 || response.status === 404) {
            console.log(`[image/uploadImagePart] Code ${response.status}`);
            // The uploaded chunk was not correct. Reset the content range and try again.
            commit(IMAGES_SET_ATTRIBUTE, {
              imageId: image.id,
              fieldName: "uploadCurrentRange",
              value: null,
            });
            commit(IMAGES_SET_ATTRIBUTE, {
              imageId: image.id,
              fieldName: "uploadId",
              value: null,
            });

            // Request a new upload id before proceeding
            await dispatch("requestUploadId", image);
            resolve(
              dispatch("uploadImagePart", {
                imageId: image.id,
                isResuming: false,
              })
            );
          } else if (response.status === 200 || response.status === 201) {
            // Chunk has been uploaded and the image has been fully uploaded
            console.log("[image/uploadImagePart] Code 200");

            // Save the uploaded file size to display the correct status
            commit(IMAGES_SET_ATTRIBUTE, {
              imageId: image.id,
              fieldName: "uploadCurrentRange",
              value: image.size,
            });

            // Save url to current image
            const url = `https://storage.googleapis.com/${process.env.VUE_APP_GCS_BUCKET}/${image.fileName}`;
            commit(IMAGES_SET_ATTRIBUTE, {
              imageId: image.id,
              fieldName: "url",
              value: url,
            });

            // Save url to reference images
            await dispatch("updateImageUrlInEntity", {
              imageId: image.id,
              entity: image.referenceEntity,
              url,
            });
            resolve();
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  updateImageUrlInEntity({ commit }, { imageId, entity, url }) {
    return new Promise(resolve => {
      commit(IMAGES_SET_IMAGE_URL_IN_ENTITY, {
        imageId,
        entity,
        url,
      });
      resolve();
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  async [IMAGES_ADD](state, { image }) {
    state.list.push(image);
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [IMAGES_SET_ATTRIBUTE](state, { imageId, fieldName, value }) {
    const index = state.list.findIndex(element => element.id == imageId);
    if (index === -1)
      throw Error(`Image with id ${imageId} not found in store`);

    const image = state.list.find(element => element.id == imageId);
    if (!image) throw Error(`Image with id ${imageId} not found in store`);

    image[fieldName] = value;

    state.list[index] = image;
  },
  [IMAGES_SET_IMAGE_URL_IN_ENTITY](state, { imageId, entity, url }) {
    // Get current image from store
    const image = store.getters["image/all"].find(item => item.id == imageId);
    if (!image) throw Error(`Image with id ${imageId} not found in store`);

    // Get referenced item from store by using the passed in entity
    const item = store.getters[`${entity}/all`].find(
      element => element.id == image.referenceId
    );
    if (!item)
      throw Error(
        `Item with entity ${entity} not found for image id ${imageId}`
      );

    const index = store.getters[`${entity}/all`].findIndex(
      element => element.id == image.referenceId
    );

    // Find references image in entity object
    let images;
    if (item.images) {
      images = item.images.filter(imageField => {
        const localField = imageField;
        if (localField.fileName == image.fileName) {
          localField.url = url;
          return localField;
        }

        return localField;
      });
    }

    store.state[entity].list[index].images = images;
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [IMAGES_DELETE](state, { fileName }) {
    const index = state.list.findIndex(element => element.fileName == fileName);
    if (index === -1) {
      console.warn(`Image with fileName ${fileName} not found in store`);
    } else {
      state.list.splice(index);
    }
  },
  /**
   * Resets state for images entity
   * @param {*} state
   */
  [IMAGES_RESET](state) {
    state.current = null;
    state.list = [];
  },
};
