<template>
  <modal
    v-model="isJobCompleteModalShowing"
    modalVariation="basic"
    style="padding-top: 22em"
  >
    <div slot="header" class="center">
      <i class="left bullhorn icon"></i>
      Aufgabe fertig melden
    </div>
    <div class="content" slot="content">
      <p>
        Nachdem eine Aufgabe „fertig” gemeldet worden ist, können keine weiteren
        Zeiten zu dieser Aufgabe hinzugefügt werden.
      </p>
      <p><strong>Möchten Sie die Aufgabe wirklich fertig melden?</strong></p>
    </div>
    <template slot="actions">
      <div class="ui black deny inverted button" @click="cancelModal">
        <i class="remove icon"></i> Nein
      </div>
      <div class="ui green right inverted button" @click="confirmModal">
        <i class="checkmark icon"></i> Ja
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "vue-semantic-modal";
import { EventBus } from "@/event-bus";

export default {
  name: "ModalImageCaptureDelete",
  props: ["jobId", "isJobCompleteModalShowing", "isJobCompleteModalConfirmed"],
  components: {
    modal,
  },
  methods: {
    cancelModal() {
      this.$emit("update:isJobCompleteModalShowing", false);
      this.$emit("update:isJobCompleteModalConfirmed", false);
    },
    confirmModal() {
      this.cancelModal();
      this.$emit("update:isJobCompleteModalConfirmed", true);
      EventBus.$emit("SHOW_JOBCOMPLETE_MODAL_CONFIRMED", this.jobId);
    },
  },
};
</script>
