<template>
  <a
    class="ui fluid card"
    :class="{ raised: isJobActive }"
    style="margin-bottom: 16px"
  >
    <div class="content" v-if="job" @click="selectJob">
      <div>
        <div
          v-if="isJobActive"
          class="status ui top right attached label tiny green"
        >
          AKTIV
        </div>
        <div
          v-else-if="isJobPaused"
          class="status ui top right attached label tiny blue"
        >
          PAUSIERT
        </div>
      </div>
      <div class="header">{{ job.title }}</div>
      <div class="description">
        <span>
          Gebäude: {{ job.building }}
          <br />
          Etage: {{ job.floor }}
          <br />
          Raum: {{ job.room }}
        </span>
      </div>
    </div>
    <div class="extra content" v-if="isExtraContentVisible">
      <ButtonJobPause
        :jobId="job.id"
        :userId="user.id"
        :isVisible="startTimelog && !stopTimelog && !job.paused_at"
      />
      <ButtonJobContinue
        :jobId="job.id"
        :userId="user.id"
        :isVisible="job.paused_at"
      />
    </div>
  </a>
</template>

<script>
import ButtonJobPause from "@/components/buttons/ButtonJobPause.vue";
import ButtonJobContinue from "@/components/buttons/ButtonJobContinue.vue";

export default {
  name: "CardJob",
  components: {
    ButtonJobPause,
    ButtonJobContinue,
  },
  props: ["job", "user"],
  computed: {
    startTimelog() {
      return this.$store.getters["timelog/startByJobId"](
        this.job.id,
        this.user.id
      );
    },
    stopTimelog() {
      if (this.startTimelog && this.startTimelog.id) {
        return this.$store.getters["timelog/stopByJobId"](
          this.job.id,
          this.user.id,
          this.startTimelog.id
        );
      }
      return null;
    },
    isJobActive() {
      return this.startTimelog && !this.stopTimelog && !this.job.paused_at;
    },
    isJobPaused() {
      return this.job.paused_at;
    },
    isExtraContentVisible() {
      return (
        (this.startTimelog && !this.stopTimelog && !this.job.paused_at) ||
        this.job.paused_at
      );
    },
  },
  methods: {
    selectJob() {
      this.$router.push({
        name: "job",
        params: {
          jobId: this.job.id,
        },
      });
    },
  },
};
</script>
