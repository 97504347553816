import store from "@/store/store";
import {
  APPROVAL_ADD,
  APPROVAL_UPDATE,
  APPROVAL_UPDATE_CURRENT,
  APPROVAL_SET_CURRENT,
  APPROVAL_SET_CURRENT_ATTRIBUTE,
  APPROVAL_SET_CURRENT_PICTURE,
  APPROVAL_DELETE_CURRENT_PICTURE,
  APPROVAL_SAVE,
  APPROVAL_DELETE,
  APPROVAL_RESET,
} from "@/store/mutation-types";
import ApprovalService from "@/services/ApprovalService.js";

export const SYNC_STATUS = Object.freeze({
  ERROR: -1,
  PENDING: 0,
  SYNCING: 1,
  SYNCED: 2,
});

export const EDIT_STATUS = Object.freeze({
  DRAFT: 0,
  COMPLETED: 1,
  CLOSED: 2,
  SENT: 3,
});

function createNewApproval({ jobId, userId }) {
  return {
    id: new Date().getTime(),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    sync_status: 0,
    staff_id: userId,
    job_id: jobId,
    approved_at: new Date().toISOString(),
    images: null,
  };
}

/**
 * Namespace
 */
export const namespaced = true;

/**
 * State
 */
export const state = {
  list: [],
  current: null,
};

/**
 * Getters
 */
export const getters = {
  current: state => state.current,
  all: state => state.list,
  pending: state =>
    state.list.filter(approval => approval && approval.sync_status !== 2),
};

/**
 * Actions
 */
export const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} jobId
   * @param {*} userId
   */
  create({ commit }, { jobId, userId }) {
    return new Promise(async resolve => {
      const approval = await createNewApproval({
        jobId,
        userId,
      });
      commit(APPROVAL_ADD, approval);
      resolve(approval);
    });
  },
  update({ commit }, { id, payload }) {
    commit(APPROVAL_UPDATE, {
      id,
      payload,
    });
  },
  updateCurrent({ commit }, { payload }) {
    return new Promise(resolve => {
      commit(APPROVAL_UPDATE_CURRENT, {
        payload,
      });
      resolve();
    });
  },
  delete({ commit, state }, { jobId }) {
    const index = state.list.findIndex(element => element.id === jobId);
    if (index === -1)
      throw Error(`Approval with id ${jobId} not found in store`);

    commit(APPROVAL_DELETE, {
      index,
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} approval
   */
  save({ commit, state }, approval) {
    return new Promise(resolve => {
      const index = state.list.findIndex(element => element.id == approval.id);
      if (index === -1)
        throw Error(`Approval with id ${approval.id} not found in store`);

      commit(APPROVAL_SAVE, {
        approval,
        index,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} approvalId
   */
  setCurrent({ commit }, approval) {
    return new Promise(resolve => {
      commit(APPROVAL_SET_CURRENT, approval);
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} jobId
   */
  setCurrentByJobId({ commit, state }, jobId) {
    return new Promise(resolve => {
      const approval = state.list.find(
        _approval => _approval.job_id == jobId.toString()
      );
      if (!approval)
        throw Error(`Approval with job_id ${jobId} not found in store`);

      commit(APPROVAL_SET_CURRENT, approval);
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  setCurrentPicture({ commit, state }, { approvalId, fileName }) {
    return new Promise(resolve => {
      // Find given timelog by id
      const approval = state.current;
      if (!approval)
        throw Error(
          `Approval with approval_id ${approvalId} not found in store`
        );

      commit(APPROVAL_SET_CURRENT_PICTURE, {
        fileName,
      });
      resolve();
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} jobId
   */
  getLatestForJob({ dispatch, state }, { jobId, userId }) {
    return new Promise(async resolve => {
      let approval = state.list.find(_approval => _approval.job_id === jobId);
      if (!approval) {
        approval = await dispatch("create", {
          jobId,
          userId,
        });
      }
      resolve(approval);
    });
  },
  /**
   * Resets all available data
   */
  reset({ commit }) {
    return new Promise(resolve => {
      commit(APPROVAL_RESET);
      resolve();
    });
  },
  /**
   * Uploads all pending timelogs
   */
  uploadPending() {
    return new Promise((resolve, reject) => {
      const items = store.getters["approval/pending"];
      console.log("[approval/uploadPending] called", items);

      // Check if pending items are available
      if (!items || items.length === 0) {
        console.log("[approval/uploadPending] No pending items available");
        resolve();
      } else {
        // Upload responses
        items
          .reduce(
            (sequence, item) =>
              sequence.then(() => {
                console.log("[approval/uploadPending] Uploading…", item);
                return store.dispatch("approval/upload", item);
              }),
            Promise.resolve()
          )
          .then(() => {
            console.log("[approval/uploadPending] Uploaded with success");
            resolve();
          })
          .catch(error => {
            console.error(
              "[approval/uploadPending] Error while uploading",
              error
            );
            reject(error);
          });
      }
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} timelog
   */
  upload({ commit, dispatch }, approval) {
    return new Promise((resolve, reject) => {
      console.log("[approval/upload] called", approval);
      // Set sync state to pending
      dispatch("update", {
        id: approval.id,
        payload: {
          sync_status: 1,
        },
      })
        .then(() =>
          // Upload images for timelog
          store.dispatch("image/uploadByReferenceId", {
            referenceId: approval.id,
          })
        )
        .then(() =>
          // Upload approval
          ApprovalService.upload(approval)
        )
        .then(response => {
          if (response.status != 201 && response.status != 200)
            throw new Error("Error uploading approval");
          return response.data.data;
        })
        .then(() => {
          // Save sync state
          commit(APPROVAL_UPDATE, {
            id: approval.id,
            payload: {
              uploaded_at: new Date().toISOString(),
              sync_status: 2,
            },
          });
          resolve();
        })
        .then(() => resolve())
        .catch(error => {
          // Catch error and set timelog to pending
          dispatch("update", {
            id: approval.id,
            payload: {
              sync_status: 0,
            },
          });
          reject(error);
        });
    });
  },
};

/**
 * Mutations
 */
export const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} approval
   */
  [APPROVAL_ADD](state, approval) {
    state.list.push(approval);
  },
  [APPROVAL_UPDATE](state, { id, payload }) {
    const index = state.list.findIndex(element => element.id == id);
    if (index === -1) throw Error(`Approval with id ${id} not found in store`);

    let approval = state.list.find(element => element.id == id);
    approval = Object.assign(approval, payload);

    state.list[index] = approval;
  },
  [APPROVAL_UPDATE_CURRENT](state, { payload }) {
    state.current = Object.assign(state.current, payload);
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [APPROVAL_SAVE](state, { approval, index }) {
    state.list.splice(index, 1, approval);
  },
  [APPROVAL_DELETE](state, { index }) {
    state.list.splice(index, 1);
  },
  /**
   *
   * @param {*} state
   * @param {*} approval
   */
  [APPROVAL_SET_CURRENT](state, approval) {
    state.current = approval;
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [APPROVAL_SET_CURRENT_ATTRIBUTE](state, { fieldName, value }) {
    state.current[fieldName] = value;
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [APPROVAL_SET_CURRENT_PICTURE](state, { fileName }) {
    if (!state.current.images) {
      state.current.images = [];
    }
    state.current.images.push({
      fileName,
      url: null,
    });
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  [APPROVAL_DELETE_CURRENT_PICTURE](state, { fileName }) {
    const index = state.current.images.findIndex(
      element => element.fileName === fileName
    );
    if (index === -1)
      throw Error(`Image with fileName ${fileName} not found in approval`);

    state.current.images.splice(index);
  },
  /**
   * Resets state for approvals entity
   * @param {*} state
   */
  [APPROVAL_RESET](state) {
    state.current = null;
    state.list = [];
  },
};
