import VuexPersistPatch from "@/plugins/vuex-persist-patch";
import abstractActions from "@/store/abstract/actions";
import abstractMutations from "@/store/abstract/mutations";
import * as approval from "@/store/modules/approval";
import * as image from "@/store/modules/image";
import * as issue from "@/store/modules/issue";
import * as job from "@/store/modules/job";
import * as project from "@/store/modules/project";
import * as remark from "@/store/modules/remark";
import * as sync from "@/store/modules/sync";
import * as timelog from "@/store/modules/timelog";
import * as user from "@/store/modules/user";
import localforage from "localforage";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

/* Abstract */
const abstract = {
  namespaced: false,
  actions: Object.assign(abstractActions),
  mutations: Object.assign(abstractMutations),
};

const vuexLocal = new VuexPersistence({
  strictMode: true,
  asyncStorage: true,
  storage: localforage,
});

const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

export default {
  strict: process.env.NODE_ENV !== "production",
  modules: {
    user,
    job,
    approval,
    issue,
    remark,
    timelog,
    image,
    project,
    sync,
    abstract,
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
  },
  plugins: [vuexLocal.plugin, VuexPersistPatch(), logrocketPlugin],
};
