/**
 * abstract/actions.js
 */

import store from "@/store/store";

const abstractActions = {
  cleanSynced({ commit }, { entity }) {
    return new Promise(resolve => {
      // Get items for given entity
      const items = store.state[entity].list.filter(
        item => item.edit_status === 2 && item.sync_status === 2
      );
      if (!items || items.length === 0) {
        console.log(
          "[abstract/cleanSynced] No completed and synced items for entity",
          entity
        );
        return resolve();
      }

      console.log(
        "[abstract/cleanSynced] Found completed and synced items for entity",
        entity,
        items
      );

      items.forEach(item => {
        // Get index in list state
        const index = store.state[entity].list.findIndex(
          element => element.id === item.id
        );
        if (index === -1) throw Error(`Index for id ${item.id} not found`);

        // Delete images for given item (if available)
        if (item.images && item.images.length > 0) {
          item.images.forEach(image => {
            try {
              commit("image/IMAGES_DELETE", {
                fileName: image.fileName,
              });
            } catch (e) {
              console.log(e);
            }
          });
        }

        // Delete item
        const mutationAction = `${entity}/${entity.toUpperCase()}_DELETE`;
        console.log(
          `[abstract/cleanSynced] Delete mutation ${mutationAction} for id`,
          item.id
        );

        try {
          commit(mutationAction, {
            index,
          });
        } catch (e) {
          console.log(e);
        }
      });

      return resolve();
    });
  },
};

export default abstractActions;
