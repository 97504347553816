var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"ui fluid left labeled icon big button",class:{
    hidden:
      _vm.job.paused_at ||
      (!(
        _vm.startTimelog &&
        _vm.stopTimelog &&
        _vm.startTimelog.start_id === _vm.stopTimelog.start_id
      ) &&
        _vm.startTimelog),
  },on:{"click":_vm.start}},[_c('i',{staticClass:"left play icon"}),_vm._v(" Arbeit starten ")])}
var staticRenderFns = []

export { render, staticRenderFns }