/**
 * Reads the current version from the manifest
 * @returns {String} Version
 */
export default function getVersionFromManifest() {
  return new Promise(resolve => {
    fetch("./manifest.json")
      .then(response => {
        if (!response.ok) {
          return resolve();
        }
        return response;
      })
      .then(response => response.json())
      .then(json => resolve(json.version))
      .catch(() => resolve());
  });
}

/**
 *
 * @param {*} dataURI
 */
export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  const blob = new Blob([dataView], {
    type: mimeString,
  });
  return blob;
}

export function formatBytes(a) {
  if (a === 0) return "0 Bytes";
  const c = 1024;
  const e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const f = Math.floor(Math.log(a) / Math.log(c));
  return `${parseFloat((a / c ** f).toFixed(2))} ${e[f]}`;
}

/**
 * Returns the percentage of two input values
 * @param {*} current
 * @param {*} total
 * @return {String} Percentage value  without %
 */
export function formatPercentage(current, total) {
  const localCurrent = current || 0;
  const localTotal = total || 0;

  return `${parseFloat((localCurrent / localTotal) * 100).toFixed(2)}`;
}

/**
 *
 * @param {*} image
 */
export function getUploadProcessForImage(image) {
  let localCurrent = image.uploadCurrentRange || 0;
  let localTotal = image.size || 0;

  let percentage = null;
  if (localCurrent === 0) {
    percentage = 0;
  } else if (image.syncStatus === 2) {
    percentage = 100;
  } else {
    percentage = formatPercentage(localCurrent, localTotal);
  }

  localTotal = formatBytes(localTotal);

  if (percentage && (percentage === 0 || percentage >= 100)) {
    const result = `${percentage} % (${localTotal}) übertragen`;
    return result;
  }

  localCurrent = formatBytes(localCurrent);
  const result = `${percentage} % (${localCurrent} / ${localTotal}) übertragen`;
  return result;
}
